import { Box, Grid } from '@mui/material';
import React, { useEffect, useRef } from 'react'
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../../../../components/Form/Input/InputField';
import LivisSwitch from '../../../../components/Switch/LivisSwitch';
import LivisCheckBox from '../../../../components/Form/Input/LivisCheckBox';
import { setAppManagementData, setUserManagementButton } from '../../../../store/actions';

const AppDetails = (props: any) => {
  const { form } = props;
  const { register, setValue, getValues, control, reset, watch, formState: { errors } } = form;
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const form_data = useSelector((state: any) => state.addAppReducer);

  const firstInputRef = useRef<any>(null);

  const validation_rule = {
    "app_name": {
      required: {
        value: true,
        message: t("Please enter App name"),
      },
      pattern: {
        value: /^[A-Za-z0-9_]+$/,
        message: t("App name can only contain alphanumeric characters and underscores"),
      },
    },
  }



  useEffect(() => {

    // console.log(getValues(), getValues().name && getValues().last_name && getValues().email && getValues().phone)
    if (getValues().app_name?.trim()?.length) {
      dispatch(setUserManagementButton(false))
    } else {
      dispatch(setUserManagementButton(true))
    }

  }, [watch, getValues(), form])

  useEffect(() => {
    dispatch(setUserManagementButton(true))
  }, [])


  const handleAppName = (e: any) => {
    const newName = e.target.value?.trim()
    reset({ app_name: newName })
    dispatch(setAppManagementData({ app_name: newName }))
  }



  return (
    <div className='livis-add-station-wrap'>
      <Grid container justifyContent={'center'}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {/* <h4 className="livis-stepper-title">{t("App Details")}</h4> */}
          <Box sx={{ m: 2 }}>
            <Grid className='livis-inspection-station-add-form' container spacing={3} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="app_name"
                  control={control}
                  rules={validation_rule['app_name']}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <>
                      <InputField
                        required={true}
                        id="app_name"
                        name="app_name"
                        label={t("App Name")}
                        type="text"
                        register={register}
                        rules={validation_rule['app_name']}
                        errors={errors}
                        livis_on_change={(e: any) => { onChange(e); handleAppName(e) }}
                        inputRef={firstInputRef}
                      />
                    </>
                  )}
                />
              </Grid>
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={validation_rule['description']}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <>
                      <InputField
                        required={true}
                        id="description"
                        name="description"
                        label={t("Description")}
                        type="text"
                        register={register}
                        rules={validation_rule['description']}
                        errors={errors}
                        livis_on_change={(e: any) => { onChange(e) }}
                      />
                    </>
                  )}
                />
              </Grid> */}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default AppDetails