import { Box, Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../../../../components/Form/Input/InputField';
import LivisAutocomplete from '../../../../components/Form/Input/LivisAutocomplete';
import { checkLowercase, checkNumber, checkSpecialCharacter, checkUppercase } from '../../../../helpers/utils';
import { setUserManagementButton, setUserManagementData } from '../../../../store/actions';

const SetPassword = (props: any) => {
  const { form } = props;
  const { register, setValue, getValues, control, watch, formState: { errors } } = form;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const firstInputRef = useRef<any>(null);




  const password_icon = process.env.PUBLIC_URL + '/image/icons/eye.svg';
  const show_password_icon = process.env.PUBLIC_URL + '/image/icons/eye_off.svg';
  const checkmark_icon = process.env.PUBLIC_URL + '/image/icons/checkmark.svg';

  // const form_data = useSelector((state: any) => state.addUserReducer);
  // const disableNextButton = useSelector((state: any) => state.addUserNextButton);



  const [changePasswordRequestStatus, setChangePasswordRequestStatus] = useState(false);
  const [isValidToken, setIsValidToken] = useState(true);
  const [userName, setUserName] = useState("");
  const [isPasswordMatched, setIsPasswordMatched] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [passwordLength, setPasswordLength] = useState(false);
  const [maxPasswordLength, setMaxPasswordLength] = useState(false);
  const [passwordLowerCase, setPasswordLowerCase] = useState(false);
  const [passwordUpperCase, setPasswordUpperCase] = useState(false);
  const [passwordNumber, setPasswordNumber] = useState(false);
  const [passwordSpecialCharacter, setPasswordSpecialCharacter] = useState(false);
  const [accessToken, setAccessToken] = useState("");



  const validation_rule = {
    "password": {
      required: {
        value: true,
        message: t("Please enter new password"),
      },
      pattern: {
        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        message: t("Password must contain at least 8 characters, a capital letter, a small letter, a number and a special character"),
      },
      minLength: {
        value: 8,
        message: t("Password must be at least 8 characters"),
      },
      maxLength: {
        value: 50,
        message: t("Password must be less than 50 characters"),
      }
    },
    "confirm_password": {
      required: {
        value: true,
        message: t("Please enter confirm password"),
      },
      validate: (val: string): any => {
        return val === getValues().password || t("Passwords do not match");
      },
      minLength: {
        value: 8,
        message: t("Password must be at least 8 characters"),
      },
      maxLength: {
        value: 50,
        message: t("Password must be less than 50 characters"),
      }
    }

  }



  useEffect(() => {
    firstInputRef.current.focus();
  }, []);

  useEffect(() => {
    console.log(getValues())
  }, [watch, getValues()])


  useEffect(() => {
    // Method for validation of password
    watch((value: any) => {
      (value.password?.length >= 8 ? setPasswordLength(true) : setPasswordLength(false));
      (value.password?.length <= 50 ? setMaxPasswordLength(true) : setMaxPasswordLength(false));
      (checkLowercase(value.password) ? setPasswordLowerCase(true) : setPasswordLowerCase(false));
      (checkUppercase(value.password) ? setPasswordUpperCase(true) : setPasswordUpperCase(false));
      (checkNumber(value.password) ? setPasswordNumber(true) : setPasswordNumber(false));
      (checkSpecialCharacter(value.password) ? setPasswordSpecialCharacter(true) : setPasswordSpecialCharacter(false));
      if (
        value.password?.length >= 8 &&
        value.password?.length <= 50 &&
        checkLowercase(value.password) &&
        checkUppercase(value.password) &&
        checkNumber(value.password) &&
        checkSpecialCharacter(value.password) &&
        value.password === value.confirm_password
      ) {
        setDisabled(false);
        setIsPasswordMatched(true);
        dispatch(setUserManagementButton(false));
        const payload = {
          ...getValues(),
          password: value.password,
          confirm_password: value.confirm_password,
        }
        dispatch(setUserManagementData(payload));
      } else {
        setDisabled(true);
        setIsPasswordMatched(false);
        dispatch(setUserManagementButton(true));
      }

    }
    );

  }, [watch])



  return (
    <div className='livis-add-station-wrap'>
      <Grid container justifyContent={'center'}>
        <Grid item lg={8} md={8} sm={8} xs={10}>
          <h4 className="livis-stepper-title">{t("Create Password")}</h4>
          <Box sx={{ m: 2 }}>
            <Grid className='livis-inspection-station-add-form' container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="password"
                  control={control}
                  rules={validation_rule['password']}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <>
                      <InputField
                        required={true}
                        id="password"
                        name="password"
                        label={t("Password")}
                        type="password"
                        register={register}
                        rules={validation_rule['password']}
                        errors={errors}
                        livis_on_change={(e: any) => { onChange(e) }}
                        inputRef={firstInputRef}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="confirm_password"
                  control={control}
                  rules={validation_rule['confirm_password']}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <>
                      <InputField
                        required={true}
                        id="confirm_password"
                        name="confirm_password"
                        label={t("Confirm Password")}
                        register={register}
                        rules={validation_rule['confirm_password']}
                        errors={errors}
                        livis_on_change={(e: any) => { onChange(e) }}
                        matched={isPasswordMatched}
                        icon={password_icon}
                        toggle_icon={show_password_icon}
                        type="password"
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </Box>


          <div className="livis-reset-password-instruction">
            <p>{t("Password must contain:")}</p>
            <ul className="livis-reset-password-instruction-ul">
              <li ><p className="livis-password-instruction-list-icon">{passwordLength ? <img data-testid="tick-icon" src={checkmark_icon} /> : '*'}</p> {t("At least 8 characters")}</li>
              <li ><p className="livis-password-instruction-list-icon">{maxPasswordLength ? <img data-testid="tick-icon" src={checkmark_icon} /> : '*'}</p> {t("Less than 50 characters")}</li>
              <li ><p className="livis-password-instruction-list-icon">{passwordLowerCase ? <img data-testid="tick-icon" src={checkmark_icon} /> : '*'}</p> {t("Lower case letter (a-z)")}</li>
              <li ><p className="livis-password-instruction-list-icon">{passwordUpperCase ? <img data-testid="tick-icon" src={checkmark_icon} /> : '*'}</p> {t("Uppercase letter (A-Z)")}</li>
              <li ><p className="livis-password-instruction-list-icon">{passwordNumber ? <img data-testid="tick-icon" src={checkmark_icon} /> : '*'}</p> {t("Number (0-9)")}</li>
              <li ><p className="livis-password-instruction-list-icon">{passwordSpecialCharacter ? <img data-testid="tick-icon" src={checkmark_icon} /> : '*'}</p> {t("Special character")}</li>
            </ul>
          </div>


        </Grid>
      </Grid>

    </div>
  )
}

export default SetPassword