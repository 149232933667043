const initialState: Array<object> = [];

/**
 * usecaseParentIDReducer: Reducer to manage the state of the use case parent ID.
 * @param {any} state - The current state of the use case parent ID.
 * @param {any} action - The action to be processed.
 * @returns {any} - The updated state with the use case parent ID.
 */

const usecaseParentIDReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_PARENT_ID': return action?.payload;
            break;
        default: return state;
    }
}

export default usecaseParentIDReducer;