const initialState = "report";

/**
 * reportTypeReducer: Reducer to handle actions related to the report type data.
 * 
 * @param {any} state - The current state of the report type.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const reportTypeReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_REPORT_TYPE': return action?.payload;
            break;
        default: return state;
    }
}

export default reportTypeReducer;