const initialState = false;

/**
 * importFormButton: Reducer to handle actions related to the state of the import form button.
 * 
 * @param {any} state - The current state of the import form button.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const importFormButton = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_IMPORT_FORM_BUTTON_STATE': return action?.payload;
            break;
        default: return state;
    }
}

export default importFormButton;