export const setLivisUserInfo = (user_info: any) => {
    localStorage.setItem('livis_user_info', user_info);
};
export const resetLivisUserInfo = () => {
    const preservedLanguage = localStorage.getItem('livis_lang'); // Preserve the language setting

    // Clear all localStorage items
    localStorage.clear();

    // Restore the language setting
    if (preservedLanguage) {
        localStorage.setItem('livis_lang', preservedLanguage);
    }
};

export const getLivisUserInfo = () => {
    return (localStorage.getItem('livis_user_info'));
};

export const getLivisToken = () => {
    let user_info: any = localStorage.getItem('livis_user_info');
    user_info = JSON.parse(user_info);
    if (user_info) {
        return user_info.access_token;
    } else {
        return null;
    }
};

export const getRefreshToken = () => {
    let user_info: any = localStorage.getItem('livis_user_info');
    user_info = JSON.parse(user_info);
    if (user_info) {
        return user_info.refresh_token;
    } else {
        return false;
    }
}

export const getAlertStatus = () => {
    const alert_status: any = localStorage.getItem('alert');
    if (alert_status) {
        return JSON.parse(alert_status);
    } else {
        return false;
    }
}

export const setNoAlertStatus = (alert_status: any) => {
    localStorage.setItem('alert', alert_status);
}

export const removeAlertStatus = () => {
    localStorage.removeItem('alert');
}

export const getLivisLanguage = () => {
    const livis_lang: any = localStorage.getItem('livis_lang') ? localStorage.getItem('livis_lang') : "en";
    return livis_lang;
};

export const getLivistheme = () => {
    const livis_theme: any = localStorage.getItem('livis_theme') ? localStorage.getItem('livis_theme') : "livis-light-theme";
    return livis_theme;
};

export const setPartList = (part_list: any) => {
    localStorage.setItem('part_list', part_list);
}

export const getPartList = () => {
    const part_list: any = localStorage.getItem('part_list');
    return JSON.parse(part_list);
}

export const setWorkstationType = (station_data: any) => {
    localStorage.setItem('station_data', JSON.stringify(station_data));
}

export const getWorkstationData = () => {
    const station_type: any = localStorage.getItem('station_data');
    return JSON.parse(station_type);
}

export const setSelectedUsecase = (usecase_data: any) => {
    localStorage.setItem("usecase_data", JSON.stringify(usecase_data));
}

export const getSelectedUsecase = () => {
    const usecase_data: any = localStorage.getItem("usecase_data");
    return JSON.parse(usecase_data);
}

export const setPLCControllerData = (plc_data: any) => {
    localStorage.setItem("plc_controller", JSON.stringify(plc_data));
}

export const getPLCControllerData = () => {
    const plc_controller: any = localStorage.getItem("plc_controller");
    return JSON.parse(plc_controller);
}

export const setCobotControllerData = (plc_data: any) => {
    localStorage.setItem("cobot_controller", JSON.stringify(plc_data));
}

export const getCobotControllerData = () => {
    const plc_controller: any = localStorage.getItem("cobot_controller");
    return JSON.parse(plc_controller);
}
export const removeCobotControllerData = () => {
    const plc_controller: any = localStorage.removeItem("cobot_controller");
    return;
}

export const setLocalSelectedPart = (part_data: any) => {
    localStorage.setItem("part_data", JSON.stringify(part_data));
}

export const getLocalSelectedPart = () => {
    const part_data: any = localStorage.getItem("part_data");
    return JSON.parse(part_data);
}

export const setLocalRecipeStation = (stationData: any) => {
    localStorage.setItem("recipe_inspection_station", JSON.stringify(stationData));
}

export const getLocalRecipeStation = () => {
    const station_data: any = localStorage.getItem("recipe_inspection_station");
    return JSON.parse(station_data);
}

export const setDataTab = (tab: string) => {
    localStorage.setItem("manage_data_tab", JSON.stringify(tab));
}

export const getDataTab = () => {
    const tab_value: any = localStorage.getItem("manage_data_tab");
    return JSON.parse(tab_value);
}