const initialState = {}

/**
 * OperatorBuilderAvailedComponents: Reducer to handle actions related to the availed components in the operator builder.
 * 
 * @param {any} state - The current state of the availed components in the operator builder.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const OperatorBuilderAvailedComponents = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_OPERATOR_BUILDER_AVAILED_COMPONENTS': return action?.payload ;
            break;
        default: return state;
    }
}

export default OperatorBuilderAvailedComponents;