const initialState: Array<object> = [];

/**
 * partUnfilteredDataReducer: Reducer to handle actions related to the unfiltered part list data.
 * 
 * @param {any} state - The current state of the unfiltered part list data.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const partUnfilteredDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_PART_UNFILTERED_LIST_DATA': return action?.payload;
            break;
        default: return state;
    }
}

export default partUnfilteredDataReducer;