import { defaultFormValues } from "../../modules/user-management/components/ManagePermissionForm/ManagePermissionFormJSON";


const initialState: any = defaultFormValues


/**
 * addPermissionReducer: Reducer to handle actions related to permission management data.
 * 
 * @param {any} state - The current state of the permission management.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const addPermissionReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_PERMISSION_MANAGEMENT_DATA': return action?.payload;
            break;
        default: return state;
    }
}

export default addPermissionReducer;