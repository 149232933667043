const initialState = false;

/**
 * operatorBuilderPreviewModal: Reducer to handle actions related to the preview modal in the operator builder.
 * 
 * @param {any} state - The current state of the preview modal.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const operatorBuilderPreviewModal = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_PREVIEW_MODAL': return action?.payload;
            break;
        default: return state;
    }
}

export default operatorBuilderPreviewModal;