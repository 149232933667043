export const tag_category_list = [
  {
    name: "Maintenance Service Parts",
    _id: 1,
    is_child_of: 0,
    icon: null,
  },
  {
    name: "DIE Cast",
    _id: 33,
    is_child_of: 0,
    icon: null,
  },
  { name: "Air Conditioning", _id: 2, is_child_of: 0, icon: null },
  {
    name: "Belts Chains and Rollers",
    _id: 3,
    is_child_of: 0,
    icon: null,
  },
  { name: "Bearings", _id: 4, is_child_of: 0, icon: null },
  { name: "Body", _id: 5, is_child_of: 0, icon: null },
  { name: "Brake System", _id: 6, is_child_of: 0, icon: null },
  { name: "Car Accessories", _id: 7, is_child_of: 0, icon: null },
  { name: "Control Cables", _id: 8, is_child_of: 0, icon: null },
  { name: "Clutch System", _id: 9, is_child_of: 0, icon: null },
  {
    name: "Electric Components",
    _id: 10,
    is_child_of: 0,
    icon: null,
  },
  { name: "Engine", _id: 11, is_child_of: 0, icon: null },
  {
    name: "Engine Cooling System",
    _id: 12,
    is_child_of: 0,
    icon: null,
  },
  { name: "Exhaust System", _id: 13, is_child_of: 0, icon: null },
  { name: "Filters", _id: 14, is_child_of: 0, icon: null },
  {
    name: "Fuel Supply System",
    _id: 15,
    is_child_of: 0,
    icon: null,
  },
  {
    name: "Gaskets and Sealing Rings",
    _id: 16,
    is_child_of: 0,
    icon: null,
  },
  {
    name: "Ignition and Glowplug System",
    _id: 17,
    is_child_of: 0,
    icon: null,
  },
  {
    name: "Interior and comfort",
    _id: 18,
    is_child_of: 0,
    icon: null,
  },
  { name: "Lighting", _id: 19, is_child_of: 0, icon: null },
  { name: "Oils and Fluids", _id: 20, is_child_of: 0, icon: null },
  { name: "Pipes and Hoses", _id: 21, is_child_of: 0, icon: null },
  { name: "Repair Kits", _id: 22, is_child_of: 0, icon: null },
  {
    name: "Sensors Relays and Control units",
    _id: 23,
    is_child_of: 0,
    icon: null,
  },
  { name: "Steering", _id: 24, is_child_of: 0, icon: null },
  {
    name: "Suspension and Arms",
    _id: 25,
    is_child_of: 0,
    icon: null,
  },
  { name: "Towbar Parts", _id: 26, is_child_of: 0, icon: null },
  { name: "Transmission", _id: 27, is_child_of: 0, icon: null },
  { name: "Trims", _id: 28, is_child_of: 0, icon: null },
  { name: "Tyres and Alloys", _id: 29, is_child_of: 0, icon: null },
  { name: "Universal", _id: 30, is_child_of: 0, icon: null },
  { name: "Wheels", _id: 31, is_child_of: 0, icon: null },
  {
    name: "Windscreen Cleaning System",
    _id: 32,
    is_child_of: 0,
    icon: null,
  },
  { name: "Others", _id: 34, is_child_of: 0, icon: null },

];

export const sub_category_list = [
  { name: "Belt", _id: 63, is_child_of: 1, icon: null },
  { name: "Brake", _id: 64, is_child_of: 1, icon: null },
  { name: "Catalogue Service Manual", _id: 65, is_child_of: 1, icon: null },
  { name: "Clutch", _id: 66, is_child_of: 1, icon: null },
  { name: "Engine Oil", _id: 67, is_child_of: 1, icon: null },
  { name: "Filter", _id: 68, is_child_of: 1, icon: null },
  { name: "Glow Plug", _id: 69, is_child_of: 1, icon: null },
  { name: "Horn", _id: 70, is_child_of: 1, icon: null },
  { name: "Light", _id: 71, is_child_of: 1, icon: null },
  { name: "Repair Service Kit", _id: 72, is_child_of: 1, icon: null },
  { name: "Shock Absorber and Strut", _id: 73, is_child_of: 1, icon: null },
  { name: "Spark Plug", _id: 74, is_child_of: 1, icon: null },
  { name: "Wiper", _id: 75, is_child_of: 1, icon: null },
  { name: "AC Compressor Clutch", _id: 76, is_child_of: 2, icon: null },
  { name: "AC Compressor Oil", _id: 77, is_child_of: 2, icon: null },
  { name: "AC Compressor Valve", _id: 78, is_child_of: 2, icon: null },
  { name: "AC Control Unit", _id: 79, is_child_of: 2, icon: null },
  { name: "AC Pressure Switch", _id: 80, is_child_of: 2, icon: null },
  { name: "AC Repair Kit", _id: 81, is_child_of: 2, icon: null },
  { name: "AC compressor", _id: 82, is_child_of: 2, icon: null },
  { name: "Air Duct", _id: 83, is_child_of: 2, icon: null },
  { name: "Air Vent", _id: 84, is_child_of: 2, icon: null },
  { name: "Blower Motor", _id: 85, is_child_of: 2, icon: null },
  { name: "Blower Motor Resistor", _id: 86, is_child_of: 2, icon: null },
  { name: "Cabin Filter", _id: 87, is_child_of: 2, icon: null },
  { name: "Cabin Temperature Sensor", _id: 88, is_child_of: 2, icon: null },
  { name: "Condenser", _id: 89, is_child_of: 2, icon: null },
  { name: "Condenser Fan", _id: 90, is_child_of: 2, icon: null },
  { name: "Condenser Fan Cowling", _id: 91, is_child_of: 2, icon: null },
  { name: "Defroster Hose", _id: 92, is_child_of: 2, icon: null },
  { name: "Evaporator", _id: 93, is_child_of: 2, icon: null },
  { name: "Expansion Valve", _id: 94, is_child_of: 2, icon: null },
  { name: "HVAC Hose", _id: 95, is_child_of: 2, icon: null },
  { name: "Heat Exchanger", _id: 96, is_child_of: 2, icon: null },
  { name: "Receiver Drier", _id: 97, is_child_of: 2, icon: null },
  { name: "Refrigerant", _id: 98, is_child_of: 2, icon: null },
  { name: "V-belt", _id: 99, is_child_of: 2, icon: null },
  { name: "Alternator Pulley", _id: 100, is_child_of: 3, icon: null },
  { name: "Belt Pulley", _id: 101, is_child_of: 3, icon: null },
  { name: "Crankshaft Pulley", _id: 102, is_child_of: 3, icon: null },
  { name: "Deflection Pulley", _id: 103, is_child_of: 3, icon: null },
  { name: "Timing Belt", _id: 104, is_child_of: 3, icon: null },
  { name: "Timing Belt Kit", _id: 105, is_child_of: 3, icon: null },
  { name: "Timing Chain", _id: 106, is_child_of: 3, icon: null },
  { name: "Timing Chain Guide", _id: 107, is_child_of: 3, icon: null },
  { name: "V-belt", _id: 108, is_child_of: 3, icon: null },
  { name: "Alternator Bearing", _id: 109, is_child_of: 4, icon: null },
  { name: "Big End Bearing", _id: 110, is_child_of: 4, icon: null },
  { name: "Clutch Release Bearing", _id: 111, is_child_of: 4, icon: null },
  { name: "Crankshaft Bearing", _id: 112, is_child_of: 4, icon: null },
  { name: "Front Wheel Bearing", _id: 113, is_child_of: 4, icon: null },
  { name: "Rear Wheel Bearing", _id: 114, is_child_of: 4, icon: null },
  { name: "Pilot Bearing", _id: 115, is_child_of: 4, icon: null },
  { name: "Shock Absorber Bearing", _id: 116, is_child_of: 4, icon: null },
  { name: "Transmission Bearing", _id: 117, is_child_of: 4, icon: null },
  { name: "Automotive Tape", _id: 118, is_child_of: 5, icon: null },
  { name: "Beam Axle", _id: 119, is_child_of: 5, icon: null },
  { name: "Body Accessories", _id: 120, is_child_of: 5, icon: null },
  { name: "Body Frame", _id: 121, is_child_of: 5, icon: null },
  { name: "Body Rubber Stop", _id: 122, is_child_of: 5, icon: null },
  { name: "Bonnet", _id: 123, is_child_of: 5, icon: null },
  { name: "Boot", _id: 124, is_child_of: 5, icon: null },
  { name: "Bumper", _id: 125, is_child_of: 5, icon: null },
  { name: "Bumper Bracket", _id: 126, is_child_of: 5, icon: null },
  { name: "Bumper Trim", _id: 127, is_child_of: 5, icon: null },
  { name: "Canopy", _id: 128, is_child_of: 5, icon: null },
  { name: "Central Locking System", _id: 129, is_child_of: 5, icon: null },
  { name: "Cowl Trim", _id: 130, is_child_of: 5, icon: null },
  { name: "Door Components", _id: 131, is_child_of: 5, icon: null },
  { name: "Door Handle Trim", _id: 132, is_child_of: 5, icon: null },
  { name: "Emblem", _id: 133, is_child_of: 5, icon: null },
  { name: "Engine Cover", _id: 134, is_child_of: 5, icon: null },
  { name: "Fender", _id: 135, is_child_of: 5, icon: null },
  { name: "Fender Bracket", _id: 136, is_child_of: 5, icon: null },
  { name: "Fender Trim", _id: 137, is_child_of: 5, icon: null },
  { name: "Fog Lamp Cover", _id: 138, is_child_of: 5, icon: null },
  { name: "Foot Step", _id: 139, is_child_of: 5, icon: null },
  { name: "Front Grill", _id: 140, is_child_of: 5, icon: null },
  { name: "Front Grill Trim", _id: 141, is_child_of: 5, icon: null },
  { name: "Fuel Tank", _id: 142, is_child_of: 5, icon: null },
  { name: "Glove Box Lock", _id: 143, is_child_of: 5, icon: null },
  { name: "Hook", _id: 144, is_child_of: 5, icon: null },
  { name: "Horn Bracket", _id: 145, is_child_of: 5, icon: null },
  { name: "Impact Absorber", _id: 146, is_child_of: 5, icon: null },
  { name: "Indicator", _id: 147, is_child_of: 5, icon: null },
  { name: "Inner Wing Panel", _id: 148, is_child_of: 5, icon: null },
  { name: "Interior Mirror", _id: 149, is_child_of: 5, icon: null },
  { name: "Licence Plate Holder", _id: 150, is_child_of: 5, icon: null },
  { name: "Mirror Glass", _id: 151, is_child_of: 5, icon: null },
  { name: "Mirror Trim", _id: 152, is_child_of: 5, icon: null },
  { name: "Noise Insulator", _id: 153, is_child_of: 5, icon: null },
  { name: "Outside Mirror", _id: 154, is_child_of: 5, icon: null },
  { name: "Outside Mirror Cover", _id: 155, is_child_of: 5, icon: null },
  { name: "Panels", _id: 156, is_child_of: 5, icon: null },
  { name: "Pillars", _id: 157, is_child_of: 5, icon: null },
  { name: "Radiator Mounting", _id: 158, is_child_of: 5, icon: null },
  { name: "Roof Rail", _id: 159, is_child_of: 5, icon: null },
  { name: "Roof Trim", _id: 160, is_child_of: 5, icon: null },
  { name: "Side Body Trim", _id: 161, is_child_of: 5, icon: null },
  { name: "Sill Trim", _id: 162, is_child_of: 5, icon: null },
  { name: "Spare Wheel Carrier", _id: 163, is_child_of: 5, icon: null },
  { name: "Speaker Grill", _id: 164, is_child_of: 5, icon: null },
  { name: "Spoiler", _id: 165, is_child_of: 5, icon: null },
  { name: "Sunroof", _id: 166, is_child_of: 5, icon: null },
  { name: "Sunroof Drain Hose", _id: 167, is_child_of: 5, icon: null },
  { name: "Tailgate Strut", _id: 168, is_child_of: 5, icon: null },
  { name: "Towhook Cover", _id: 169, is_child_of: 5, icon: null },
  { name: "Wheel Arch Trim", _id: 170, is_child_of: 5, icon: null },
  { name: "Window Guide Rail", _id: 171, is_child_of: 5, icon: null },
  { name: "Window Seal", _id: 172, is_child_of: 5, icon: null },
  { name: "Windshield", _id: 173, is_child_of: 5, icon: null },
  { name: "Windshield Seal", _id: 174, is_child_of: 5, icon: null },
  { name: "Accessory Kit Brake Pads", _id: 175, is_child_of: 6, icon: null },
  { name: "Accessory Kit Brake Shoes", _id: 176, is_child_of: 6, icon: null },
  { name: "Anti Lock Braking System", _id: 177, is_child_of: 6, icon: null },
  { name: "Brake Booster", _id: 178, is_child_of: 6, icon: null },
  { name: "Brake Cable", _id: 179, is_child_of: 6, icon: null },
  { name: "Brake Caliper", _id: 180, is_child_of: 6, icon: null },
  { name: "Brake Caliper Piston", _id: 181, is_child_of: 6, icon: null },
  { name: "Brake Caliper Repair Kit", _id: 182, is_child_of: 6, icon: null },
  { name: "Brake Disc", _id: 183, is_child_of: 6, icon: null },
  { name: "Brake Disc Back plate", _id: 184, is_child_of: 6, icon: null },
  { name: "Brake Disc Bolt", _id: 185, is_child_of: 6, icon: null },
  { name: "Brake Drum Back Plate", _id: 186, is_child_of: 6, icon: null },
  { name: "Brake Fluid", _id: 187, is_child_of: 6, icon: null },
  { name: "Brake Fluid Reservoir", _id: 188, is_child_of: 6, icon: null },
  { name: "Brake Hose", _id: 189, is_child_of: 6, icon: null },
  { name: "Brake Hose Connector", _id: 190, is_child_of: 6, icon: null },
  { name: "Brake Light Switch", _id: 191, is_child_of: 6, icon: null },
  { name: "Brake Master Cylinder", _id: 192, is_child_of: 6, icon: null },
  {
    name: "Brake Master Cylinder Repair Kit",
    _id: 193,
    is_child_of: 6,
    icon: null,
  },
  { name: "Brake Pads", _id: 194, is_child_of: 6, icon: null },
  { name: "Brake Pads Wear Sensor", _id: 195, is_child_of: 6, icon: null },
  { name: "Brake Pedal", _id: 196, is_child_of: 6, icon: null },
  { name: "Brake Pipe", _id: 197, is_child_of: 6, icon: null },
  { name: "Brake Power Regulator", _id: 198, is_child_of: 6, icon: null },
  { name: "Brake Proportioning Valve", _id: 199, is_child_of: 6, icon: null },
  { name: "Brake Shoe Lining", _id: 200, is_child_of: 6, icon: null },
  { name: "Brake Shoes", _id: 201, is_child_of: 6, icon: null },
  { name: "Brake System Bracket", _id: 202, is_child_of: 6, icon: null },
  { name: "Caliper Bracket", _id: 203, is_child_of: 6, icon: null },
  { name: "Drum Brake", _id: 204, is_child_of: 6, icon: null },
  { name: "Drum Brake Adjuster", _id: 205, is_child_of: 6, icon: null },
  { name: "Handbrake", _id: 206, is_child_of: 6, icon: null },
  { name: "Handbrake Repair Kit", _id: 207, is_child_of: 6, icon: null },
  {
    name: "Handbrake Warning Light Switch",
    _id: 208,
    is_child_of: 6,
    icon: null,
  },
  { name: "Vacuum Hose", _id: 209, is_child_of: 6, icon: null },
  { name: "Vacuum Pump", _id: 210, is_child_of: 6, icon: null },
  { name: "Wheel Brake Cylinder", _id: 211, is_child_of: 6, icon: null },
  {
    name: "Wheel Brake Cylinder Repair Kit",
    _id: 212,
    is_child_of: 6,
    icon: null,
  },
  { name: "Backup Camera", _id: 213, is_child_of: 7, icon: null },
  { name: "Car Decal Sticker", _id: 214, is_child_of: 7, icon: null },
  { name: "Car Exterior Accessories", _id: 215, is_child_of: 7, icon: null },
  { name: "Car Interior Accessories", _id: 216, is_child_of: 7, icon: null },
  { name: "Emergency Breakdown", _id: 217, is_child_of: 7, icon: null },
  { name: "Mobile Holder", _id: 218, is_child_of: 7, icon: null },
  { name: "Other Accessories", _id: 219, is_child_of: 7, icon: null },
  { name: "Accelerator Cable", _id: 220, is_child_of: 8, icon: null },
  { name: "Bonnet Release cable", _id: 221, is_child_of: 8, icon: null },
  { name: "Brake Cable", _id: 222, is_child_of: 8, icon: null },
  { name: "Clutch Cable", _id: 223, is_child_of: 8, icon: null },
  { name: "Door Lock Link", _id: 224, is_child_of: 8, icon: null },
  { name: "Fuel Lid Cable", _id: 225, is_child_of: 8, icon: null },
  { name: "Gear Shift Cable", _id: 226, is_child_of: 8, icon: null },
  { name: "Seat Cable", _id: 227, is_child_of: 8, icon: null },
  { name: "Temperature Control Cable", _id: 228, is_child_of: 8, icon: null },
  { name: "Clutch Cable", _id: 229, is_child_of: 9, icon: null },
  { name: "Clutch Control Switch", _id: 230, is_child_of: 9, icon: null },
  { name: "Clutch Disc", _id: 231, is_child_of: 9, icon: null },
  { name: "Clutch Hose", _id: 232, is_child_of: 9, icon: null },
  { name: "Clutch Kit", _id: 233, is_child_of: 9, icon: null },
  { name: "Clutch Master Cylinder", _id: 234, is_child_of: 9, icon: null },
  {
    name: "Clutch Master Cylinder Reservoir",
    _id: 235,
    is_child_of: 9,
    icon: null,
  },
  { name: "Clutch Pedal", _id: 236, is_child_of: 9, icon: null },
  { name: "Clutch Pressure Plate", _id: 237, is_child_of: 9, icon: null },
  { name: "Clutch Release Bearing", _id: 238, is_child_of: 9, icon: null },
  { name: "Clutch Release Fork", _id: 239, is_child_of: 9, icon: null },
  { name: "Clutch Release Lever", _id: 240, is_child_of: 9, icon: null },
  { name: "Clutch Release Shaft", _id: 241, is_child_of: 9, icon: null },
  { name: "Clutch Repair Kit", _id: 242, is_child_of: 9, icon: null },
  { name: "Clutch Slave Cylinder", _id: 243, is_child_of: 9, icon: null },
  { name: "Clutch Sleeve", _id: 244, is_child_of: 9, icon: null },
  { name: "Flywheel", _id: 245, is_child_of: 9, icon: null },
  { name: "Pilot Bearing", _id: 246, is_child_of: 9, icon: null },
  { name: "Alternator", _id: 247, is_child_of: 10, icon: null },
  { name: "Antenna Cable", _id: 248, is_child_of: 10, icon: null },
  { name: "Audio System", _id: 249, is_child_of: 10, icon: null },
  { name: "Battery", _id: 250, is_child_of: 10, icon: null },
  { name: "Battery Cable", _id: 251, is_child_of: 10, icon: null },
  { name: "Battery Cover", _id: 252, is_child_of: 10, icon: null },
  { name: "Battery Holder", _id: 253, is_child_of: 10, icon: null },
  { name: "Battery Terminal", _id: 254, is_child_of: 10, icon: null },
  { name: "Battery Terminal Cap", _id: 255, is_child_of: 10, icon: null },
  { name: "Bonnet Release Cable", _id: 256, is_child_of: 10, icon: null },
  { name: "Boot Release Cable", _id: 257, is_child_of: 10, icon: null },
  { name: "Central Locking System", _id: 258, is_child_of: 10, icon: null },
  { name: "Control Unit", _id: 259, is_child_of: 10, icon: null },
  { name: "Control Unit Bracket", _id: 260, is_child_of: 10, icon: null },
  { name: "Electric Drive", _id: 261, is_child_of: 10, icon: null },
  { name: "Fuse", _id: 262, is_child_of: 10, icon: null },
  { name: "Fuse Box", _id: 263, is_child_of: 10, icon: null },
  { name: "Glow Plug", _id: 264, is_child_of: 10, icon: null },
  { name: "Horn", _id: 265, is_child_of: 10, icon: null },
  {
    name: "Information and Communication System",
    _id: 266,
    is_child_of: 10,
    icon: null,
  },
  {
    name: "Intake Air Temperature Sender Unit",
    _id: 267,
    is_child_of: 10,
    icon: null,
  },
  { name: "Light", _id: 268, is_child_of: 10, icon: null },
  { name: "Mirror Actuator", _id: 269, is_child_of: 10, icon: null },
  { name: "Power Window Motor", _id: 270, is_child_of: 10, icon: null },
  { name: "Printed Circuit Board", _id: 271, is_child_of: 10, icon: null },
  { name: "Radiator Fan Motor", _id: 272, is_child_of: 10, icon: null },
  { name: "Reflector", _id: 273, is_child_of: 10, icon: null },
  { name: "Relay", _id: 274, is_child_of: 10, icon: null },
  { name: "Seat Cable", _id: 275, is_child_of: 10, icon: null },
  { name: "Security System", _id: 276, is_child_of: 10, icon: null },
  { name: "Sensor", _id: 277, is_child_of: 10, icon: null },
  { name: "Spark Plug", _id: 278, is_child_of: 10, icon: null },
  { name: "Speedometer Cable", _id: 279, is_child_of: 10, icon: null },
  { name: "Starter", _id: 280, is_child_of: 10, icon: null },
  { name: "Starter Relay", _id: 281, is_child_of: 10, icon: null },
  { name: "Sunroof Motor", _id: 282, is_child_of: 10, icon: null },
  { name: "Switch", _id: 283, is_child_of: 10, icon: null },
  {
    name: "Temperature Control Cable",
    _id: 284,
    is_child_of: 10,
    icon: null,
  },
  { name: "V-belt", _id: 285, is_child_of: 10, icon: null },
  { name: "Voltage Stabilizer", _id: 286, is_child_of: 10, icon: null },
  {
    name: "Windscreen Cleaning Parts",
    _id: 287,
    is_child_of: 10,
    icon: null,
  },
  { name: "Wiring Cover", _id: 288, is_child_of: 10, icon: null },
  { name: "Wiring Harness", _id: 289, is_child_of: 10, icon: null },
  { name: "Wiring Harness Bracket", _id: 290, is_child_of: 10, icon: null },
  { name: "Adjusting Potentiometer", _id: 291, is_child_of: 11, icon: null },
  { name: "Air Supply", _id: 292, is_child_of: 11, icon: null },
  { name: "Belt and Chain Drive", _id: 293, is_child_of: 11, icon: null },
  { name: "Big End Bearing", _id: 294, is_child_of: 11, icon: null },
  { name: "Camshaft Bush", _id: 295, is_child_of: 11, icon: null },
  { name: "Camshaft Gear", _id: 296, is_child_of: 11, icon: null },
  { name: "Camshaft Seal", _id: 297, is_child_of: 11, icon: null },
  { name: "Carburettor", _id: 298, is_child_of: 11, icon: null },
  { name: "Carburettor Flange", _id: 299, is_child_of: 11, icon: null },
  { name: "Connecting rod", _id: 300, is_child_of: 11, icon: null },
  {
    name: "Control Unit Glow Plug System",
    _id: 301,
    is_child_of: 11,
    icon: null,
  },
  { name: "Cooler EGR", _id: 302, is_child_of: 11, icon: null },
  { name: "Cooling System", _id: 303, is_child_of: 11, icon: null },
  { name: "Crankcase Gasket", _id: 304, is_child_of: 11, icon: null },
  { name: "Crankshaft Bearing", _id: 305, is_child_of: 11, icon: null },
  { name: "Crankshaft Gear", _id: 306, is_child_of: 11, icon: null },
  { name: "Crankshaft Seal", _id: 307, is_child_of: 11, icon: null },
  { name: "Cylinder Block", _id: 308, is_child_of: 11, icon: null },
  { name: "Cylinder Head", _id: 309, is_child_of: 11, icon: null },
  { name: "Cylinder Head Cover", _id: 310, is_child_of: 11, icon: null },
  {
    name: "Cylinder Head Cover Gasket",
    _id: 311,
    is_child_of: 11,
    icon: null,
  },
  { name: "Cylinder Head Gasket", _id: 312, is_child_of: 11, icon: null },
  { name: "Cylinder Head Gasket Set", _id: 313, is_child_of: 11, icon: null },
  { name: "Distributor", _id: 314, is_child_of: 11, icon: null },
  { name: "Distributor Cap", _id: 315, is_child_of: 11, icon: null },
  { name: "Distributor Rotor", _id: 316, is_child_of: 11, icon: null },
  { name: "Drain Plug", _id: 317, is_child_of: 11, icon: null },
  { name: "EGR Valve Gasket", _id: 318, is_child_of: 11, icon: null },
  { name: "Engine Block", _id: 319, is_child_of: 11, icon: null },
  { name: "Engine Bracket", _id: 320, is_child_of: 11, icon: null },
  { name: "Engine Full Gasket Set", _id: 321, is_child_of: 11, icon: null },
  { name: "Engine Manifold", _id: 322, is_child_of: 11, icon: null },
  {
    name: "Exhaust Gas Recirculation Valve",
    _id: 323,
    is_child_of: 11,
    icon: null,
  },
  { name: "Exhaust Manifold Gasket", _id: 324, is_child_of: 11, icon: null },
  { name: "Fan Clutch", _id: 325, is_child_of: 11, icon: null },
  { name: "Flywheel", _id: 326, is_child_of: 11, icon: null },
  { name: "Fuel Rail", _id: 327, is_child_of: 11, icon: null },
  { name: "Heat Shield", _id: 328, is_child_of: 11, icon: null },
  {
    name: "Idle Control Valve Air supply",
    _id: 329,
    is_child_of: 11,
    icon: null,
  },
  { name: "Ignition", _id: 330, is_child_of: 11, icon: null },
  { name: "Ignition Cable", _id: 331, is_child_of: 11, icon: null },
  { name: "Ignition Capacitor", _id: 332, is_child_of: 11, icon: null },
  { name: "Ignition Coil", _id: 333, is_child_of: 11, icon: null },
  { name: "Ignition Module", _id: 334, is_child_of: 11, icon: null },
  {
    name: "Ignition Plug Sleeve Connector",
    _id: 335,
    is_child_of: 11,
    icon: null,
  },
  { name: "Injector", _id: 336, is_child_of: 11, icon: null },
  { name: "Injector Seal", _id: 337, is_child_of: 11, icon: null },
  { name: "Intake Manifold Gasket", _id: 338, is_child_of: 11, icon: null },
  {
    name: "Intercooler Mounting Bracket",
    _id: 339,
    is_child_of: 11,
    icon: null,
  },
  { name: "Lubrication", _id: 340, is_child_of: 11, icon: null },
  {
    name: "O-Ring Set Cylinder Sleeve",
    _id: 341,
    is_child_of: 11,
    icon: null,
  },
  { name: "Oil Cooler", _id: 342, is_child_of: 11, icon: null },
  { name: "Gasket Oil Dipstick", _id: 343, is_child_of: 11, icon: null },
  { name: "Oil Dipstick Funnel", _id: 344, is_child_of: 11, icon: null },
  { name: "Oil Filler Cap and Seal", _id: 345, is_child_of: 11, icon: null },
  { name: "Oil Hose", _id: 346, is_child_of: 11, icon: null },
  { name: "Oil Pressure Switch", _id: 347, is_child_of: 11, icon: null },
  { name: "Oil Pump Seal Gasket", _id: 348, is_child_of: 11, icon: null },
  { name: "Oil Separator", _id: 349, is_child_of: 11, icon: null },
  { name: "Oil Spray Nozzle", _id: 350, is_child_of: 11, icon: null },
  { name: "Oil Sump Gasket", _id: 351, is_child_of: 11, icon: null },
  { name: "PCV Breather Hose", _id: 352, is_child_of: 11, icon: null },
  { name: "PCV Valve", _id: 353, is_child_of: 11, icon: null },
  { name: "Radiator Cap", _id: 354, is_child_of: 11, icon: null },
  { name: "Radiator Hose", _id: 355, is_child_of: 11, icon: null },
  { name: "Sensor", _id: 356, is_child_of: 11, icon: null },
  { name: "Starter Motor Assembly", _id: 357, is_child_of: 11, icon: null },
  { name: "Thermostat Gasket", _id: 358, is_child_of: 11, icon: null },
  { name: "Throttle Body", _id: 359, is_child_of: 11, icon: null },
  { name: "Throttle Body Seal", _id: 360, is_child_of: 11, icon: null },
  { name: "Timing Case Gasket", _id: 361, is_child_of: 11, icon: null },
  { name: "Vacuum Hose", _id: 362, is_child_of: 11, icon: null },
  { name: "Vacuum Modulator", _id: 363, is_child_of: 11, icon: null },
  { name: "Vacuum Pump", _id: 364, is_child_of: 11, icon: null },
  { name: "Water Pump Gasket", _id: 365, is_child_of: 11, icon: null },
  { name: "Coolant", _id: 366, is_child_of: 12, icon: null },
  { name: "Coolant Control Valve", _id: 367, is_child_of: 12, icon: null },
  { name: "Coolant Flange", _id: 368, is_child_of: 12, icon: null },
  { name: "Coolant Pipe Seal", _id: 369, is_child_of: 12, icon: null },
  { name: "Coolant Tank", _id: 370, is_child_of: 12, icon: null },
  { name: "Coolant Tank Cap", _id: 371, is_child_of: 12, icon: null },
  {
    name: "Coolant Temperature Sensor",
    _id: 372,
    is_child_of: 12,
    icon: null,
  },
  { name: "Cooling Fan", _id: 373, is_child_of: 12, icon: null },
  { name: "Fan Clutch", _id: 374, is_child_of: 12, icon: null },
  { name: "Radiator", _id: 375, is_child_of: 12, icon: null },
  { name: "Radiator Cap", _id: 376, is_child_of: 12, icon: null },
  { name: "Radiator Fan Cowling", _id: 377, is_child_of: 12, icon: null },
  { name: "Radiator Fan Motor", _id: 378, is_child_of: 12, icon: null },
  { name: "Radiator Hose", _id: 379, is_child_of: 12, icon: null },
  { name: "Radiator Mounting", _id: 380, is_child_of: 12, icon: null },
  { name: "Thermostat", _id: 381, is_child_of: 12, icon: null },
  { name: "Thermostat Gasket", _id: 382, is_child_of: 12, icon: null },
  { name: "Water Pump", _id: 383, is_child_of: 12, icon: null },
  { name: "Water Pump Gasket", _id: 384, is_child_of: 12, icon: null },
  { name: "Catalytic Converter", _id: 385, is_child_of: 13, icon: null },
  { name: "Cooler EGR", _id: 386, is_child_of: 13, icon: null },
  { name: "EGR Pipe", _id: 387, is_child_of: 13, icon: null },
  { name: "EGR Valve", _id: 388, is_child_of: 13, icon: null },
  { name: "EGR Valve Gasket", _id: 389, is_child_of: 13, icon: null },
  {
    name: "Exhaust Gas Temperature Sensor",
    _id: 390,
    is_child_of: 13,
    icon: null,
  },
  { name: "Exhaust Manifold", _id: 391, is_child_of: 13, icon: null },
  { name: "Exhaust Manifold Gasket", _id: 392, is_child_of: 13, icon: null },
  { name: "Exhaust Pipe", _id: 393, is_child_of: 13, icon: null },
  { name: "Exhaust Pipe Gasket", _id: 394, is_child_of: 13, icon: null },
  { name: "Exhaust System Bracket", _id: 395, is_child_of: 13, icon: null },
  {
    name: "Exhaust System Rubber Strip",
    _id: 396,
    is_child_of: 13,
    icon: null,
  },
  { name: "Exhaust Tip", _id: 397, is_child_of: 13, icon: null },
  { name: "Intercooler", _id: 398, is_child_of: 13, icon: null },
  { name: "Lambda Sensor", _id: 399, is_child_of: 13, icon: null },
  { name: "Resonator", _id: 400, is_child_of: 13, icon: null },
  { name: "Silencer", _id: 401, is_child_of: 13, icon: null },
  { name: "Turbocharger", _id: 402, is_child_of: 13, icon: null },
  { name: "Turbocharger Gasket", _id: 403, is_child_of: 13, icon: null },
  { name: "Turbocharger Hose", _id: 404, is_child_of: 13, icon: null },
  { name: "Air Filter", _id: 405, is_child_of: 14, icon: null },
  {
    name: "Automatic Transmission Filter",
    _id: 406,
    is_child_of: 14,
    icon: null,
  },
  { name: "Cabin Filter", _id: 407, is_child_of: 14, icon: null },
  { name: "Filter Set", _id: 408, is_child_of: 14, icon: null },
  { name: "Fuel Filter", _id: 409, is_child_of: 14, icon: null },
  { name: "Fuel Pump Filter", _id: 410, is_child_of: 14, icon: null },
  { name: "Oil Filter", _id: 411, is_child_of: 14, icon: null },
  { name: "Receiver Drier", _id: 412, is_child_of: 14, icon: null },
  { name: "Accelerator Cable", _id: 413, is_child_of: 15, icon: null },
  { name: "Accelerator Pedal", _id: 414, is_child_of: 15, icon: null },
  { name: "Carburettor", _id: 415, is_child_of: 15, icon: null },
  { name: "Carburettor Flange", _id: 416, is_child_of: 15, icon: null },
  { name: "Carburettor Repair Kit", _id: 417, is_child_of: 15, icon: null },
  {
    name: "Carburettor Solenoid Valve",
    _id: 418,
    is_child_of: 15,
    icon: null,
  },
  { name: "Charcoal Canister", _id: 419, is_child_of: 15, icon: null },
  { name: "Fuel Cap", _id: 420, is_child_of: 15, icon: null },
  { name: "Fuel Filler Door Housing", _id: 421, is_child_of: 15, icon: null },
  { name: "Fuel Filler Hose", _id: 422, is_child_of: 15, icon: null },
  { name: "Fuel Filter", _id: 423, is_child_of: 15, icon: null },
  { name: "Fuel Lid Cable", _id: 424, is_child_of: 15, icon: null },
  { name: "Fuel Lid Opener Lever", _id: 425, is_child_of: 15, icon: null },
  { name: "Fuel Line", _id: 426, is_child_of: 15, icon: null },
  { name: "Fuel Pressure Regulator", _id: 427, is_child_of: 15, icon: null },
  { name: "Fuel Pump", _id: 428, is_child_of: 15, icon: null },
  { name: "Fuel Pump Filter", _id: 429, is_child_of: 15, icon: null },
  { name: "Fuel Pump Seal", _id: 430, is_child_of: 15, icon: null },
  { name: "Fuel Rail", _id: 431, is_child_of: 15, icon: null },
  { name: "Fuel Sensor", _id: 432, is_child_of: 15, icon: null },
  { name: "Fuel System Bracket", _id: 433, is_child_of: 15, icon: null },
  { name: "Fuel System Connector", _id: 434, is_child_of: 15, icon: null },
  { name: "Fuel System Cover", _id: 435, is_child_of: 15, icon: null },
  { name: "Fuel Tank", _id: 436, is_child_of: 15, icon: null },
  { name: "Fuel Valve", _id: 437, is_child_of: 15, icon: null },
  { name: "Injection System", _id: 438, is_child_of: 15, icon: null },
  { name: "LPG and CNG System", _id: 439, is_child_of: 15, icon: null },
  { name: "Throttle Body", _id: 440, is_child_of: 15, icon: null },
  { name: "Bonnet Seal", _id: 441, is_child_of: 16, icon: null },
  { name: "Camshaft Seal", _id: 442, is_child_of: 16, icon: null },
  { name: "Coolant Pipe Seal", _id: 443, is_child_of: 16, icon: null },
  { name: "Crankcase Gasket", _id: 444, is_child_of: 16, icon: null },
  { name: "Crankshaft Seal", _id: 445, is_child_of: 16, icon: null },
  {
    name: "Cylinder Head Cover Gasket",
    _id: 446,
    is_child_of: 16,
    icon: null,
  },
  { name: "Cylinder Head Gasket", _id: 447, is_child_of: 16, icon: null },
  { name: "Cylinder head Gasket Set", _id: 448, is_child_of: 16, icon: null },
  { name: "Door Seal", _id: 449, is_child_of: 16, icon: null },
  { name: "EGR Valve Gasket", _id: 450, is_child_of: 16, icon: null },
  { name: "Engine Full Gasket Set", _id: 451, is_child_of: 16, icon: null },
  { name: "Exhaust Manifold Gasket", _id: 452, is_child_of: 16, icon: null },
  { name: "Exhaust Pipe Gasket", _id: 453, is_child_of: 16, icon: null },
  { name: "Injector Seal", _id: 454, is_child_of: 16, icon: null },
  { name: "Intake Manifold Gasket", _id: 455, is_child_of: 16, icon: null },
  {
    name: "O-Ring Set Cylinder Sleeve",
    _id: 456,
    is_child_of: 16,
    icon: null,
  },
  { name: "Oil Cooler Gasket", _id: 457, is_child_of: 16, icon: null },
  { name: "Oil Pump Seal Gasket", _id: 458, is_child_of: 16, icon: null },
  { name: "Oil Sump Gasket", _id: 459, is_child_of: 16, icon: null },
  { name: "Seal Fuel Filter", _id: 460, is_child_of: 16, icon: null },
  { name: "Seal Injection Pump", _id: 461, is_child_of: 16, icon: null },
  { name: "Shaft Seal Wheel Hub", _id: 462, is_child_of: 16, icon: null },
  { name: "Thermostat Gasket", _id: 463, is_child_of: 16, icon: null },
  { name: "Throttle Body Seal", _id: 464, is_child_of: 16, icon: null },
  { name: "Timing Case Gasket", _id: 465, is_child_of: 16, icon: null },
  { name: "Transmission Gasket", _id: 466, is_child_of: 16, icon: null },
  { name: "Transmission Seal", _id: 467, is_child_of: 16, icon: null },
  { name: "Turbocharger Gasket", _id: 468, is_child_of: 16, icon: null },
  { name: "Water Pump Gasket", _id: 469, is_child_of: 16, icon: null },
  { name: "Window Seal", _id: 470, is_child_of: 16, icon: null },
  { name: "Contact Breaker", _id: 471, is_child_of: 17, icon: null },
  {
    name: "Control Unit Glow Plug System",
    _id: 472,
    is_child_of: 17,
    icon: null,
  },
  { name: "Distributor", _id: 473, is_child_of: 17, icon: null },
  { name: "Distributor Cap", _id: 474, is_child_of: 17, icon: null },
  { name: "Distributor Rotor", _id: 475, is_child_of: 17, icon: null },
  { name: "Glow Plug", _id: 476, is_child_of: 17, icon: null },
  { name: "Ignition Cable", _id: 477, is_child_of: 17, icon: null },
  { name: "Ignition Coil", _id: 478, is_child_of: 17, icon: null },
  { name: "Ignition Module", _id: 479, is_child_of: 17, icon: null },
  {
    name: "Ignition Plug Sleeve Connector",
    _id: 480,
    is_child_of: 17,
    icon: null,
  },
  { name: "Ignition Switch", _id: 481, is_child_of: 17, icon: null },
  { name: "Spark Plug", _id: 482, is_child_of: 17, icon: null },
  { name: "AC Control Unit", _id: 483, is_child_of: 18, icon: null },
  { name: "Accelerator Pedal", _id: 484, is_child_of: 18, icon: null },
  { name: "Air Bag System", _id: 485, is_child_of: 18, icon: null },
  { name: "Air Vent", _id: 486, is_child_of: 18, icon: null },
  { name: "Armrest", _id: 487, is_child_of: 18, icon: null },
  { name: "Ashtray", _id: 488, is_child_of: 18, icon: null },
  { name: "Brake Light Switch", _id: 489, is_child_of: 18, icon: null },
  { name: "Brake Pedal", _id: 490, is_child_of: 18, icon: null },
  { name: "Cargo Area Light", _id: 491, is_child_of: 18, icon: null },
  { name: "Central Locking System", _id: 492, is_child_of: 18, icon: null },
  { name: "Centre Console", _id: 493, is_child_of: 18, icon: null },
  { name: "Clutch Pedal", _id: 494, is_child_of: 18, icon: null },
  { name: "Cup Holder", _id: 495, is_child_of: 18, icon: null },
  { name: "Dashboard", _id: 496, is_child_of: 18, icon: null },
  { name: "Dashboard Trim", _id: 497, is_child_of: 18, icon: null },
  { name: "Door Handle", _id: 498, is_child_of: 18, icon: null },
  { name: "Door Lock", _id: 499, is_child_of: 18, icon: null },
  { name: "Door Lock Knob", _id: 500, is_child_of: 18, icon: null },
  { name: "Fog Lamp Switch", _id: 501, is_child_of: 18, icon: null },
  { name: "Fuel Lid Opener Lever", _id: 502, is_child_of: 18, icon: null },
  { name: "Gear Selector Mechanism", _id: 503, is_child_of: 18, icon: null },
  { name: "Glove Box", _id: 504, is_child_of: 18, icon: null },
  { name: "Glove Box Lock", _id: 505, is_child_of: 18, icon: null },
  {
    name: "Handbrake Warning Light Switch",
    _id: 506,
    is_child_of: 18,
    icon: null,
  },
  { name: "Hazard Light Switch", _id: 507, is_child_of: 18, icon: null },
  { name: "Headliner", _id: 508, is_child_of: 18, icon: null },
  { name: "Headrest", _id: 509, is_child_of: 18, icon: null },
  { name: "Interior Attachment", _id: 510, is_child_of: 18, icon: null },
  { name: "Interior Door Panel", _id: 511, is_child_of: 18, icon: null },
  { name: "Interior Grab Handle", _id: 512, is_child_of: 18, icon: null },
  { name: "Interior Mirror", _id: 513, is_child_of: 18, icon: null },
  { name: "Mirror Adjustment Switch", _id: 514, is_child_of: 18, icon: null },
  { name: "Parcel Tray", _id: 515, is_child_of: 18, icon: null },
  {
    name: "Passenger Compartment Light",
    _id: 516,
    is_child_of: 18,
    icon: null,
  },
  { name: "Pedal", _id: 517, is_child_of: 18, icon: null },
  { name: "Pedal Pad", _id: 518, is_child_of: 18, icon: null },
  { name: "Pedal Repair Kit", _id: 519, is_child_of: 18, icon: null },
  { name: "Power Window Motor", _id: 520, is_child_of: 18, icon: null },
  { name: "Seating System", _id: 521, is_child_of: 18, icon: null },
  { name: "Speedometer Cable", _id: 522, is_child_of: 18, icon: null },
  { name: "Steering Column Switch", _id: 523, is_child_of: 18, icon: null },
  { name: "Sun Visor", _id: 524, is_child_of: 18, icon: null },
  { name: "Sun Visor Holder", _id: 525, is_child_of: 18, icon: null },
  { name: "Window Crank", _id: 526, is_child_of: 18, icon: null },
  {
    name: "Window Regulator Sliding Shoe",
    _id: 527,
    is_child_of: 18,
    icon: null,
  },
  { name: "Window Switch", _id: 528, is_child_of: 18, icon: null },
  { name: "Window Regulator", _id: 529, is_child_of: 18, icon: null },
  { name: "Auxiliary Exterior Light", _id: 530, is_child_of: 19, icon: null },
  { name: "Auxiliary Stop Light", _id: 531, is_child_of: 19, icon: null },
  { name: "Bulb", _id: 532, is_child_of: 19, icon: null },
  { name: "Cargo Area Light", _id: 533, is_child_of: 19, icon: null },
  { name: "Daytime Running Light", _id: 534, is_child_of: 19, icon: null },
  { name: "Fog Lamp", _id: 535, is_child_of: 19, icon: null },
  { name: "Fog Lamp Components", _id: 536, is_child_of: 19, icon: null },
  { name: "Headlight", _id: 537, is_child_of: 19, icon: null },
  { name: "Headlight Components", _id: 538, is_child_of: 19, icon: null },
  { name: "Indicator", _id: 539, is_child_of: 19, icon: null },
  { name: "Indicator Components", _id: 540, is_child_of: 19, icon: null },
  {
    name: "Interior Light Components",
    _id: 541,
    is_child_of: 19,
    icon: null,
  },
  { name: "Number Plate Light", _id: 542, is_child_of: 19, icon: null },
  {
    name: "Passenger Compartment Light",
    _id: 543,
    is_child_of: 19,
    icon: null,
  },
  { name: "Puddle Light", _id: 544, is_child_of: 19, icon: null },
  { name: "Rearlight Components", _id: 545, is_child_of: 19, icon: null },
  { name: "Tail Light", _id: 546, is_child_of: 19, icon: null },
  { name: "AC Compressor Oil", _id: 547, is_child_of: 20, icon: null },
  { name: "Additives", _id: 548, is_child_of: 20, icon: null },
  { name: "Brake Fluid", _id: 549, is_child_of: 20, icon: null },
  { name: "Coolant", _id: 550, is_child_of: 20, icon: null },
  { name: "Engine Oil", _id: 551, is_child_of: 20, icon: null },
  { name: "Exhaust Fluid", _id: 552, is_child_of: 20, icon: null },
  { name: "Steering Oil", _id: 553, is_child_of: 20, icon: null },
  { name: "Transmission Oil", _id: 554, is_child_of: 20, icon: null },
  { name: "Windshield Washer Fluid", _id: 555, is_child_of: 20, icon: null },
  { name: "Air Filter Pipe", _id: 556, is_child_of: 21, icon: null },
  { name: "Brake Hose", _id: 557, is_child_of: 21, icon: null },
  { name: "Brake Pipe", _id: 558, is_child_of: 21, icon: null },
  { name: "Fuel Line", _id: 559, is_child_of: 21, icon: null },
  { name: "HVAC Hose", _id: 560, is_child_of: 21, icon: null },
  { name: "Oil Hose", _id: 561, is_child_of: 21, icon: null },
  { name: "PCV Breather Hose", _id: 562, is_child_of: 21, icon: null },
  { name: "Radiator Hose", _id: 563, is_child_of: 21, icon: null },
  { name: "Steering Hose", _id: 564, is_child_of: 21, icon: null },
  { name: "Transmission Hose", _id: 565, is_child_of: 21, icon: null },
  { name: "Turbocharger Hose", _id: 566, is_child_of: 21, icon: null },
  { name: "Vacuum Hose", _id: 567, is_child_of: 21, icon: null },
  { name: "AC Repair Kit", _id: 568, is_child_of: 22, icon: null },
  { name: "Accessory Kit Brake Pads", _id: 569, is_child_of: 22, icon: null },
  {
    name: "Accessory Kit Brake Shoes",
    _id: 570,
    is_child_of: 22,
    icon: null,
  },
  { name: "Brake Caliper Repair Kit", _id: 571, is_child_of: 22, icon: null },
  {
    name: "Brake Master Cylinder Repair Kit",
    _id: 572,
    is_child_of: 22,
    icon: null,
  },
  { name: "Carburettor Repair Kit", _id: 573, is_child_of: 22, icon: null },
  { name: "Clutch Repair Kit", _id: 574, is_child_of: 22, icon: null },
  { name: "Gear Shifter Repair Kit", _id: 575, is_child_of: 22, icon: null },
  { name: "Handbrake Repair Kit", _id: 576, is_child_of: 22, icon: null },
  { name: "Pedal Repair Kit", _id: 577, is_child_of: 22, icon: null },
  {
    name: "Power Steering Pump Repair Kit",
    _id: 578,
    is_child_of: 22,
    icon: null,
  },
  { name: "Starter Motor Repair Kit", _id: 579, is_child_of: 22, icon: null },
  { name: "Steering Gear Repair Kit", _id: 580, is_child_of: 22, icon: null },
  { name: "Suspension Kit", _id: 581, is_child_of: 22, icon: null },
  { name: "Timing Belt Kit", _id: 582, is_child_of: 22, icon: null },
  {
    name: "Wheel Brake Cylinder Repair Kit",
    _id: 583,
    is_child_of: 22,
    icon: null,
  },
  { name: "ABS Sensor", _id: 584, is_child_of: 23, icon: null },
  { name: "AC Control Unit", _id: 585, is_child_of: 23, icon: null },
  { name: "Blower Motor Resistor", _id: 586, is_child_of: 23, icon: null },
  { name: "Brake Light Switch", _id: 587, is_child_of: 23, icon: null },
  { name: "Brake Pad Wear Sensor", _id: 588, is_child_of: 23, icon: null },
  { name: "Cabin Temperature Sensor", _id: 589, is_child_of: 23, icon: null },
  { name: "Camshaft Sensor", _id: 590, is_child_of: 23, icon: null },
  {
    name: "Control Unit Glow Plug System",
    _id: 591,
    is_child_of: 23,
    icon: null,
  },
  {
    name: "Coolant Temperature Sensor",
    _id: 592,
    is_child_of: 23,
    icon: null,
  },
  { name: "Crankshaft RPM Sensor", _id: 593, is_child_of: 23, icon: null },
  {
    name: "Exhaust Gas Temperature Sensor",
    _id: 594,
    is_child_of: 23,
    icon: null,
  },
  { name: "Fuel Pressure Regulator", _id: 595, is_child_of: 23, icon: null },
  { name: "Fuel Sensor", _id: 596, is_child_of: 23, icon: null },
  { name: "Horn Relay", _id: 597, is_child_of: 23, icon: null },
  { name: "Knock Sensor", _id: 598, is_child_of: 23, icon: null },
  { name: "Lambda Sensor", _id: 599, is_child_of: 23, icon: null },
  { name: "Mass Air Flow Sensor", _id: 600, is_child_of: 23, icon: null },
  { name: "Oil Pressure Switch", _id: 601, is_child_of: 23, icon: null },
  { name: "Parking Sensor", _id: 602, is_child_of: 23, icon: null },
  { name: "Power Window Motor", _id: 603, is_child_of: 23, icon: null },
  {
    name: "Sensor Intake Manifold Pressure",
    _id: 604,
    is_child_of: 23,
    icon: null,
  },
  {
    name: "Starter Motor Solenoid Switch",
    _id: 605,
    is_child_of: 23,
    icon: null,
  },
  { name: "Starter Relay", _id: 606, is_child_of: 23, icon: null },
  { name: "Throttle Position Sensor", _id: 607, is_child_of: 23, icon: null },
  { name: "Transmission RPM Sensor", _id: 608, is_child_of: 23, icon: null },
  { name: "Window Switch", _id: 609, is_child_of: 23, icon: null },
  { name: "Centre Rod Assembly", _id: 610, is_child_of: 24, icon: null },
  { name: "Horn Pad", _id: 611, is_child_of: 24, icon: null },
  { name: "Idler Arm", _id: 612, is_child_of: 24, icon: null },
  { name: "Inner Tie Rod", _id: 613, is_child_of: 24, icon: null },
  { name: "King Pin", _id: 614, is_child_of: 24, icon: null },
  { name: "Pitman Arm", _id: 615, is_child_of: 24, icon: null },
  { name: "Power Steering Motor", _id: 616, is_child_of: 24, icon: null },
  { name: "Power Steering Pump", _id: 617, is_child_of: 24, icon: null },
  {
    name: "Power Steering Pump Repair Kit",
    _id: 618,
    is_child_of: 24,
    icon: null,
  },
  { name: "Power Steering Reservoir", _id: 619, is_child_of: 24, icon: null },
  {
    name: "Power Steering Reservoir Cap",
    _id: 620,
    is_child_of: 24,
    icon: null,
  },
  { name: "Steering Angle Sensor", _id: 621, is_child_of: 24, icon: null },
  { name: "Steering Bracket", _id: 622, is_child_of: 24, icon: null },
  { name: "Steering Column", _id: 623, is_child_of: 24, icon: null },
  { name: "Steering Column Coupler", _id: 624, is_child_of: 24, icon: null },
  { name: "Steering Column Cover", _id: 625, is_child_of: 24, icon: null },
  { name: "Steering Column Switch", _id: 626, is_child_of: 24, icon: null },
  { name: "Steering Damper", _id: 627, is_child_of: 24, icon: null },
  { name: "Steering Gear", _id: 628, is_child_of: 24, icon: null },
  { name: "Steering Gear Assembly", _id: 629, is_child_of: 24, icon: null },
  { name: "Steering Gear Repair Kit", _id: 630, is_child_of: 24, icon: null },
  { name: "Steering Hose", _id: 631, is_child_of: 24, icon: null },
  { name: "Steering Knuckle", _id: 632, is_child_of: 24, icon: null },
  { name: "Steering Mounting", _id: 633, is_child_of: 24, icon: null },
  { name: "Steering Oil", _id: 634, is_child_of: 24, icon: null },
  { name: "Steering Rack Boot", _id: 635, is_child_of: 24, icon: null },
  { name: "Steering Wheel", _id: 636, is_child_of: 24, icon: null },
  { name: "Tie Rod End", _id: 637, is_child_of: 24, icon: null },
  { name: "Air Suspension Boot", _id: 638, is_child_of: 25, icon: null },
  { name: "Arm Bush", _id: 639, is_child_of: 25, icon: null },
  { name: "Beam Axle", _id: 640, is_child_of: 25, icon: null },
  { name: "Compressed Air System", _id: 641, is_child_of: 25, icon: null },
  { name: "Inner Tie Rod", _id: 642, is_child_of: 25, icon: null },
  { name: "Leaf Spring Shackle", _id: 643, is_child_of: 25, icon: null },
  { name: "Shaft Seal Wheel Hub", _id: 644, is_child_of: 25, icon: null },
  { name: "Shock Absorber and Strut", _id: 645, is_child_of: 25, icon: null },
  { name: "Spring", _id: 646, is_child_of: 25, icon: null },
  { name: "Stabilizer Components", _id: 647, is_child_of: 25, icon: null },
  { name: "Steering Knuckle", _id: 648, is_child_of: 25, icon: null },
  { name: "Suspension Bracket", _id: 649, is_child_of: 25, icon: null },
  { name: "Suspension Kit", _id: 650, is_child_of: 25, icon: null },
  { name: "Suspension Links", _id: 651, is_child_of: 25, icon: null },
  { name: "Suspension Nut Bolt", _id: 652, is_child_of: 25, icon: null },
  { name: "Suspension Seal", _id: 653, is_child_of: 25, icon: null },
  { name: "Tie Rod End", _id: 654, is_child_of: 25, icon: null },
  { name: "Towbar", _id: 655, is_child_of: 26, icon: null },
  { name: "Towhook Cover", _id: 656, is_child_of: 26, icon: null },
  {
    name: "Automatic Transmission Filter",
    _id: 657,
    is_child_of: 27,
    icon: null,
  },
  {
    name: "Automatic Transmission Lining Disc",
    _id: 658,
    is_child_of: 27,
    icon: null,
  },
  {
    name: "Automatic Transmission Oil Cooler",
    _id: 659,
    is_child_of: 27,
    icon: null,
  },
  { name: "Axle Driveshaft", _id: 660, is_child_of: 27, icon: null },
  { name: "Clutch", _id: 661, is_child_of: 27, icon: null },
  { name: "Flywheel", _id: 662, is_child_of: 27, icon: null },
  { name: "Gear Selector Mechanism", _id: 663, is_child_of: 27, icon: null },
  { name: "Gearbox", _id: 664, is_child_of: 27, icon: null },
  { name: "Reverse Light Switch", _id: 665, is_child_of: 27, icon: null },
  { name: "Torque Converter", _id: 666, is_child_of: 27, icon: null },
  { name: "Transfer Case Repair Kit", _id: 667, is_child_of: 27, icon: null },
  { name: "Transmission Bearing", _id: 668, is_child_of: 27, icon: null },
  { name: "Transmission Collar", _id: 669, is_child_of: 27, icon: null },
  { name: "Transmission Gasket", _id: 670, is_child_of: 27, icon: null },
  { name: "Transmission Hose", _id: 671, is_child_of: 27, icon: null },
  { name: "Transmission Oil", _id: 672, is_child_of: 27, icon: null },
  { name: "Transmission Oil Sump", _id: 673, is_child_of: 27, icon: null },
  { name: "Transmission RPM Sensor", _id: 674, is_child_of: 27, icon: null },
  { name: "Transmission Seal", _id: 675, is_child_of: 27, icon: null },
  { name: "Universal Joint", _id: 676, is_child_of: 27, icon: null },
  { name: "Bumper", _id: 677, is_child_of: 28, icon: null },
  { name: "Bumper Trim", _id: 678, is_child_of: 28, icon: null },
  { name: "Emblem", _id: 679, is_child_of: 28, icon: null },
  { name: "Front Grill", _id: 680, is_child_of: 28, icon: null },
  { name: "Inner Wing Panel", _id: 681, is_child_of: 28, icon: null },
  { name: "Mirrors", _id: 682, is_child_of: 28, icon: null },
  { name: "Sill Trim", _id: 683, is_child_of: 28, icon: null },
  { name: "Spoiler", _id: 684, is_child_of: 28, icon: null },
  { name: "Passenger Car Tyres", _id: 685, is_child_of: 29, icon: null },
  { name: "Tyre Valve", _id: 686, is_child_of: 29, icon: null },
  { name: "Wheel Cover", _id: 687, is_child_of: 29, icon: null },
  { name: "Wheel Rim", _id: 688, is_child_of: 29, icon: null },
  { name: "Bolt", _id: 689, is_child_of: 30, icon: null },
  { name: "Cable Strap", _id: 690, is_child_of: 30, icon: null },
  { name: "Circlip", _id: 691, is_child_of: 30, icon: null },
  { name: "Clamp", _id: 692, is_child_of: 30, icon: null },
  { name: "Clip", _id: 693, is_child_of: 30, icon: null },
  { name: "Grease", _id: 694, is_child_of: 30, icon: null },
  { name: "Grommet", _id: 695, is_child_of: 30, icon: null },
  { name: "Nut", _id: 696, is_child_of: 30, icon: null },
  { name: "O-Ring", _id: 697, is_child_of: 30, icon: null },
  { name: "Plug", _id: 698, is_child_of: 30, icon: null },
  { name: "Rivet", _id: 699, is_child_of: 30, icon: null },
  { name: "Screw", _id: 700, is_child_of: 30, icon: null },
  { name: "Sealing Substance", _id: 701, is_child_of: 30, icon: null },
  { name: "Spacer", _id: 702, is_child_of: 30, icon: null },
  { name: "Stud", _id: 703, is_child_of: 30, icon: null },
  { name: "Tightening Strap", _id: 704, is_child_of: 30, icon: null },
  { name: "Washer", _id: 705, is_child_of: 30, icon: null },
  { name: "ABS Ring", _id: 706, is_child_of: 31, icon: null },
  { name: "Drive Shaft", _id: 707, is_child_of: 31, icon: null },
  { name: "Drive Shaft Boot", _id: 708, is_child_of: 31, icon: null },
  { name: "Propeller Shaft", _id: 709, is_child_of: 31, icon: null },
  { name: "Rim", _id: 710, is_child_of: 31, icon: null },
  { name: "Spare Wheel Cover", _id: 711, is_child_of: 31, icon: null },
  { name: "Wheel Balancing Weight", _id: 712, is_child_of: 31, icon: null },
  { name: "Wheel Bearing", _id: 713, is_child_of: 31, icon: null },
  { name: "Wheel Hubs", _id: 714, is_child_of: 31, icon: null },
  {
    name: "Connector Washer Fluid Pipe",
    _id: 715,
    is_child_of: 32,
    icon: null,
  },
  { name: "Rain Sensor", _id: 716, is_child_of: 32, icon: null },
  { name: "Washer Fluid Pipe", _id: 717, is_child_of: 32, icon: null },
  {
    name: "Windshield Washer Bracket",
    _id: 718,
    is_child_of: 32,
    icon: null,
  },
  { name: "Windshield Washer Fluid", _id: 719, is_child_of: 32, icon: null },
  { name: "Windshield Washer Jet", _id: 720, is_child_of: 32, icon: null },
  { name: "Windshield Washer Pump", _id: 721, is_child_of: 32, icon: null },
  {
    name: "Windshield Washer Reservoir",
    _id: 722,
    is_child_of: 32,
    icon: null,
  },
  {
    name: "Windshield Washer Reservoir Cap",
    _id: 723,
    is_child_of: 32,
    icon: null,
  },
  { name: "Wiper Arm", _id: 724, is_child_of: 32, icon: null },
  { name: "Wiper Arm Cap", _id: 725, is_child_of: 32, icon: null },
  { name: "Wiper Blade", _id: 726, is_child_of: 32, icon: null },
  {
    name: "Wiper Blade Headlight Cleaning",
    _id: 727,
    is_child_of: 32,
    icon: null,
  },
  { name: "Wiper Blade Rubber", _id: 728, is_child_of: 32, icon: null },
  { name: "Wiper Blade Set", _id: 729, is_child_of: 32, icon: null },
  { name: "Wiper Linkage", _id: 730, is_child_of: 32, icon: null },
  { name: "Wiper Motor", _id: 731, is_child_of: 32, icon: null },
  { name: "Wiper System Seal", _id: 732, is_child_of: 32, icon: null },
  { name: "Others", _id: 734, is_child_of: 34, icon: null },
  { name: "Battery Tray Pack", _id: 733, is_child_of: 33, icon: null },
];

export const process_category_list = [
  { name: "CNC", _id: 1500, is_child_of: 0, icon: null },
  { name: "InjectionMoulding", _id: 1501, is_child_of: 0, icon: null },
  { name: "Forging", _id: 1502, is_child_of: 0, icon: null },
  { name: "Painting", _id: 1503, is_child_of: 0, icon: null },
  { name: "Stamping", _id: 1504, is_child_of: 0, icon: null },
  { name: "Assembly", _id: 1505, is_child_of: 0, icon: null },
  { name: "Grinding", _id: 1506, is_child_of: 0, icon: null },
  { name: "Honing", _id: 1507, is_child_of: 0, icon: null },
  { name: "Lapping", _id: 1508, is_child_of: 0, icon: null },
  { name: "Turning", _id: 1509, is_child_of: 0, icon: null },
  { name: "Drilling", _id: 1510, is_child_of: 0, icon: null },
  { name: "Milling", _id: 1511, is_child_of: 0, icon: null },
  { name: "Shaping", _id: 1512, is_child_of: 0, icon: null },
  { name: "Planing", _id: 1513, is_child_of: 0, icon: null },
  { name: "Broaching", _id: 1514, is_child_of: 0, icon: null },
  { name: "Sawing", _id: 1515, is_child_of: 0, icon: null },
  { name: "Rolling", _id: 1516, is_child_of: 0, icon: null },
  { name: "Forging", _id: 1517, is_child_of: 0, icon: null },
  { name: "Extrusion", _id: 1518, is_child_of: 0, icon: null },
  { name: "Bending", _id: 1519, is_child_of: 0, icon: null },
  { name: "Drawing", _id: 1520, is_child_of: 0, icon: null },
  { name: "Shearing", _id: 1521, is_child_of: 0, icon: null },
  { name: "Casting", _id: 1522, is_child_of: 0, icon: null },
  { name: "Blow Molding", _id: 1523, is_child_of: 0, icon: null },
  { name: "Calendering", _id: 1524, is_child_of: 0, icon: null },
  // { name: "Casting", _id: 1525, is_child_of: 0, icon: null },
  { name: "Compression Molding", _id: 1526, is_child_of: 0, icon: null },
  { name: "Extrusion", _id: 1527, is_child_of: 0, icon: null },
  { name: "Rotational Molding", _id: 1528, is_child_of: 0, icon: null },
  { name: "Thermoforming", _id: 1529, is_child_of: 0, icon: null },
  { name: "Joining", _id: 1530, is_child_of: 0, icon: null },
  { name: "Others", _id: 1531, is_child_of: 0, icon: null },
];

export const material_category_list = [
  {name: "Rubber", _id: 16000, is_child_of: 0, icon: null},
  {name: "Plastic", _id: 16001, is_child_of: 0, icon: null},
  {name: "Paper", _id: 16002, is_child_of: 0, icon: null},
  {name: "Glass", _id: 16003, is_child_of: 0, icon: null},
  {name: "Cast Iron", _id: 16004, is_child_of: 0, icon: null},
  {name: "Stainless Steel", _id: 16005, is_child_of: 0, icon: null},
  {name: "Carbon Fiber", _id: 16006, is_child_of: 0, icon: null},
  {name: "Titanium", _id: 16007, is_child_of: 0, icon: null},

  {name: "Timber", _id: 16007, is_child_of: 0, icon: null},
  {name: "X Ray", _id: 16007, is_child_of: 0, icon: null},
  {name: "Aluminium", _id: 16010, is_child_of: 0, icon: null},
  {name: "Others", _id: 16011, is_child_of: 0, icon: null},

]
