const initialState = false;

/**
 * isAgumentedReducer: Reducer to handle actions related to the augmented state.
 * 
 * @param {any} state - The current state of the augmented status.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const isAgumentedReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_IS_AUGMENTED': return action?.payload;
            break;
        default: return state;
    }
}

export default isAgumentedReducer;