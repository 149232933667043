import { defaultFormValues } from "../../modules/user-management/components/ManageAppForm/ManageAppFormJSON";


const initialState: any = defaultFormValues


/**
 * addAppReducer: Reducer to handle actions related to app management data.
 * 
 * @param {any} state - The current state of the reducer.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */

const addAppReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_APP_MANAGEMENT_DATA': return action?.payload;
            break;
        default: return state;
    }
}

export default addAppReducer;