const initialState: Array<object> = [];

/**
 * usecaseListReducer: Reducer to manage the list of use cases.
 * @param {any} state - The current state of the use case list data.
 * @param {any} action - The action to be processed.
 * @returns {any} - The updated state with the use case list data.
 */

const usecaseListReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_USECASE_LIST_DATA': return action?.payload;
            break;
        default: return state;
    }
}

export default usecaseListReducer;