import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const cobot_image =  process.env.PUBLIC_URL + "/image/icons/Cobot.png";
const conveyor_image =  process.env.PUBLIC_URL + "/image/icons/conveyor.png";
const static_image =  process.env.PUBLIC_URL + "/image/icons/Static_station.png";
const mobile_image =  process.env.PUBLIC_URL + "/image/icons/mobile_icon.png";


// export const plc_values = {
//   plc_name: "",
//   communication_protocol: "",
//   interface_address: "",
// };

// export const camera_values = {
//   camera_name: "",
//   camera_type: "",
//   camera_address: "",
// };

// export const communication_protocol = [
//   "Modbus TCP", "Modbus RTU", "Profiniet", "Socket / TCP", "Serial Communication"
// ]

// export const camera_type = [
//   "IP Cam", "USB Cam", "GIG-E (Baumer)", "GIG-E (Lucid)"
// ];

// export const camera_direction = [
//   "Right to Left", "Left to Right"
// ];

// export const camera_control = [
//   "Absolute", "Increment"
// ];

export const useStationType = () => {
  const {t} = useTranslation()
  const station_type = [
    {
      id: "co-bot",
      type: "cobot",
      label: t("Cobot"),
      image: cobot_image,
    },
    {
      id: "conveyor",
      type: "conveyor",
      label: t("Conveyor"),
      image: conveyor_image,
    },
    {
      id: "static-station",
      type: "static",
      label: t("Static Station"),
      image: static_image,
    },
    {
      id: "mobile-station",
      type: "mobile",
      label: t("Mobile Station"),
      image: mobile_image,
    },
  ];
  return station_type
}

export const station_type =  [
  {
    id: "co-bot",
    type: "cobot",
    label: t("Cobot"),
    image: cobot_image,
  },
  {
    id: "conveyor",
    type: "conveyor",
    label: t("Conveyor"),
    image: conveyor_image,
  },
  {
    id: "static-station",
    type: "static",
    label: t("Static Station"),
    image: static_image,
  },
  {
    id: "mobile-station",
    type: "mobile",
    label: t("Mobile Station"),
    image: mobile_image,
  },
];

export const useInspectionModalOptions = () => {
  const {t} = useTranslation()
  const inspection_model_options = [
    t("Anomaly"), t("Supervised"), t("Both")
  ]
  return inspection_model_options
}

export const inspection_model_options = [
  t("Anomaly"), t("Supervised"), t("Both")
]

export const useInspectionStationType = () => {
  const {t} = useTranslation()
  const inspection_station_type = [
    t("cobot"), t("conveyor"), t("static"), t("mobile")
  ]
  return inspection_station_type
}

export const inspection_station_type = [
  t("cobot"), t("conveyor"), t("static"), t("mobile")
]

export const defaultRecipeFormValues= {
    recipe_name: "",
    inspection_model: "",
    workstation_type: "",
    workstation_id: "",
    list_of_parts: [],
    usecase_bucket: {},
    input_controller: {
      start_process: {
        plc_id: "",
        register: "00",
        idle_value: "00",
        active_value: "00",
      },
      stop_process: {
        plc_id: "",
        register: "00",
        idle_value: "00",
        active_value: "00",
      },
      // pause_resume_process: {
      //   plc_id: "",
      //   register: "00",
      //   idle_value: "00",
      //   active_value: "00",
      // },
      trigger_process: {
        plc_id: "",
        register: "00",
        idle_value: "00",
        active_value: "00",
      },
      trigger_process_stop: {
        plc_id: "",
        register: "00",
        idle_value: "00",
        active_value: "00",
      },
      in_process_calibration: {
        plc_id: "",
        register: "00",
        idle_value: "00",
        active_value: "00",
      },
      reset_current_inspection: {
        plc_id: "",
        register: "00",
        idle_value: "00",
        active_value: "00",
      },
      // strobe_output: {
      //   plc_id: "",
      //   register: "00",
      //   idle_value: "00",
      //   active_value: "00",
      // }
    },
    output_controller: {
      // towerlamp: {
      //   controller_type: "",
      //   plc_id: "",
      //   register: "00",
      //   idle_value: "00",
      //   active_value: "00",
      // },
      // segregation_pneumatic: {
      //   plc_id: "",
      //   register: "00",
      //   idle_value: "00",
      //   active_value: "00",
      // },
      // process_running_indicator: {
      //   plc_id: "",
      //   register: "00",
      //   idle_value: "00",
      //   active_value: "00",
      // },
    },
    operator_layout_id: "",
  }
  
  export const useRecipeForm = () => {
    return useForm({
      mode: "all",
      defaultValues: defaultRecipeFormValues
    });
  };

  export const getInputControllerTypeLabel = () => {
    const {t} = useTranslation()
    const input_controller_type_label = [
      {
        key: "start_process",
        label: t("Start Process")
      },
      {
        key: "stop_process",
        label: t("Stop Process")
      },
      // {
      //   key: "pause_resume_process",
      //   label: "Pause/ Resume Process"
      // },
      {
        key: "trigger_process",
        label: t("Trigger Process")
      },
      {
        key: "trigger_process_stop",
        label: t("Trigger Stop Process")
      },
      // {
      //   key: "strobe_output",
      //   label: "Strobe Output For Lighting"
      // }
    ]
    return input_controller_type_label
  }

  export const input_controller_type_label = [
    {
      key: "start_process",
      label: "Start Process"
    },
    {
      key: "stop_process",
      label: "Stop Process"
    },
    // {
    //   key: "pause_resume_process",
    //   label: "Pause/ Resume Process"
    // },
    {
      key: "trigger_process",
      label: "Trigger Process"
    },
    {
      key: "trigger_process_stop",
      label: "Trigger Stop Process"
    },
    {
      key: "in_process_calibration",
      label: "In Process Calibration"
    },
    {
      key: "reset_current_inspection",
      label: "Reset Current Inspection"
    },
    // {
    //   key: "strobe_output",
    //   label: "Strobe Output For Lighting"
    // }
  ]

  export const getOutputControllerTypeLabel = () => {
    const {t} = useTranslation()
    const output_controller_type_label = [
      {
        key: "towerlamp",
        label: t("Towerlamp")
      },
      {
        key: "segregation_pneumatic",
        label: t("Segregation Pneumatic")
      },
      {
        key: "process_running_indicator",
        label: t("Process Running Indicator")
      }
    ];
    return output_controller_type_label
  }

  export const output_controller_type_label = [
    {
      key: "towerlamp",
      label: "Towerlamp"
    },
    {
      key: "segregation_pneumatic",
      label: "Segregation Pneumatic"
    },
    {
      key: "process_running_indicator",
      label: "Process Running Indicator"
    }
  ];

  export const output_display_label: any = {
    towerlamp: "Towerlamp",
    segregation_pneumatic: "Segregation Pneumatic",
    process_running_indicator: "Process Running Indicator"
  }

  export const dummyResponse = [{
    "_id": "fasjdfakljsdfhhfkljad",
    "usecase_name": "Dummy Usecase",
    "accuracy": "0.80",
    "created_at": "2023-08-23T08:55:35.53400",
    "usecase_features": ["fillet", "Chamfer"],
    "usecase_defects": ["roughness", "crack", "pinhole"],
    "experiments": [{
      "experiment_name": "DSH87HH007",
      "final_score": "0.84",
      "_id": "fasjdhfkljad"
    },
    {
      "experiment_name": "DSH87HH002",
      "final_score": "0.81",
      "_id": "uioawyerlbd"
    }]
  },
  {
    "_id": "fasjafajhgsdfadhfkljad",
    "usecase_name": "Dummy Usecase 1",
    "accuracy": "0.80",
    "created_at": "2023-08-23T08:55:35.53400",
    "usecase_features": ["fillet", "Chamfer"],
    "usecase_defects": ["roughness", "crack", "pinhole"],
    "experiments": [{
      "experiment_name": "WSH87HH007",
      "final_score": "0.94",
      "_id": "fasjdhfkljadsadfas"
    },
    {
      "experiment_name": "WSH87HH002",
      "final_score": "0.51",
      "_id": "fasjdhfkljadsduioz"
    }]
  }
];

export const dummyPLCResponse = [
  {
      "plc_name": "PLC 01",
      "communication_protocol": "Modbus TCP",
      "interface_address": "0.0.0.0"
  },
  {
    "plc_name": "PLC 02",
    "communication_protocol": "Modbus TCP",
    "interface_address": "0.0.0.1"
  }
]

export const getTowerLampPurpose = () => {
  const {t} = useTranslation()
  const towerlampPurpose = [
    t("accepted"), t("rejected"),t("start_process")
  ]
  return towerlampPurpose
}

export const towerlampPurpose = [
  "accepted", "rejected","start_process"
]