import { defaultRecipeFormValues } from "../../modules/recipe-builder/components/AddRecipeStepperForm/RecipeFormJSON";


const initialState = {
    recipeFormData: defaultRecipeFormValues
}

/**
 * recipeFormReducer: Reducer to handle actions related to the recipe form data.
 * 
 * @param {any} state - The current state of the recipe form data.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const recipeFormReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_RECIPE_FORM_DATA': return { recipeFormData: action?.payload };
            break;
        default: return state;
    }
}

export default recipeFormReducer;