const initialState: Array<object> = [];

/**
 * trackHistroyOfOperatorPanelBuilder: Reducer to manage the history tracking of the operator panel builder.
 * @param {any} state - The current state of the operator panel history.
 * @param {any} action - The action to be processed.
 * @returns {any} - The updated state based on the action type.
 */

const trackHistroyOfOperatorPanelBuilder = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_TRACK_HISTORY_OF_OPERATOR_PANEL_BUILDER': return action?.payload;
            break;
        default: return state;
    }
}

export default trackHistroyOfOperatorPanelBuilder;