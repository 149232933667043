const initialState: Array<object> = [];

/**
 * inspectionStationListReducer: Reducer to handle actions related to inspection station data.
 * 
 * @param {any} state - The current state of the inspection station data.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const inspectionStationListReducer = (state = initialState, action: any) => {
    switch (action?.type) {
        case 'SET_INSPECTION_STATION_DATA': return action?.payload;
            break;
        default: return state;
    }
}

export default inspectionStationListReducer;