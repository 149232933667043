const initialState = false

/**
 * sidebarAPIReducer: Reducer to manage the state for the sidebar API.
 * @param {any} state - The current state of the sidebar API.
 * @param {any} action - The action to be processed.
 * @returns {any} - The updated state based on the action type.
 */
const sidebarAPIReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_SIDE_BAR_API': return action?.payload;
            break;
        default: return state;
    }
}

export default sidebarAPIReducer;