import { AppRoutes } from './routes';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from './components/Loader/Spinner/Spinner';
import SpinnerTranslucent from './components/Loader/Spinner/SpinnerTranslucent';
import { setIsLoading, setIsLoadingTranslucent } from './store/actions';
import { useEffect } from 'react';
import { hideConsoleLogs } from './helpers/utils';



function App() {

  // Hide console logs in production
  hideConsoleLogs();


  const isLoading = useSelector((state: any) => state.spinner.isLoading);
  const isLoadingTranslucent = useSelector((state: any) => state.spinnerTranslucent.isLoading);

  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoading) {
      if (isLoadingTranslucent) {
        dispatch(setIsLoadingTranslucent(false))
        dispatch(setIsLoading(true))
      }
    }
    if (isLoadingTranslucent) {
      if (isLoading) {
        dispatch(setIsLoadingTranslucent(true))
        dispatch(setIsLoading(false))
      }
    }

  }, [isLoading, isLoadingTranslucent])


  return (
    <div className='App'>
      <Spinner is_loading={isLoading} />
      <SpinnerTranslucent is_loading={isLoadingTranslucent} />
      <ToastContainer />
      <AppRoutes />
    </div>
  );
}

export default App;
