const initialState = false;

/**
 * recipeNextButtonReducer: Reducer to handle actions related to the state of the recipe next button.
 * 
 * @param {any} state - The current state of the recipe next button.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */

const recipeNextButtonReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_RECIPE_BUTTON_STATE': return action?.payload;
            break;
        default: return state;
    }
}

export default recipeNextButtonReducer;