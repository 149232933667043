import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../components/Loader/Spinner/Spinner';
import { resetLivisUserInfo } from '../../../helpers/storage';
import { setIsLoading } from '../../../store/actions';
import { logoutWithAccessToken } from '../../../services/auth';
import { livisAlert } from '../../../helpers/alert';
import { doesUserHasAccess } from '../../../helpers/utils';
import { useTranslation } from 'react-i18next';

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector((state: any) => state.spinner.isLoading)
    const {t} = useTranslation()



    useEffect(() => {
        const hasAccess = doesUserHasAccess(55)
        if (!hasAccess) {
            livisAlert(`${t("User does not have permission to perform this action")}`, "warning")
            navigate("/app/inspection/station")
            dispatch(setIsLoading(false))
            return
        }
        dispatch(setIsLoading(true));
        // alert("yes")
        logoutUser()
    }, [])

    const logoutUser = async () => {
        const user_info: any = JSON.parse(localStorage.getItem('livis_user_info') || "{}");
        const payload: any = {
            "access_token": user_info?.access_token,
        }
        const response = await logoutWithAccessToken(payload);
        resetLivisUserInfo();
        livisAlert(`${t("Logout Successful")}`, "success");
        navigate('/auth/login');
    }

    return (
        <>
            <Spinner is_loading={isLoading} />
        </>
    );
}

export default Logout;