const initialState = false;

/**
 * usecaseNextButtonReducer: Reducer to manage the state of the use case next button.
 * @param {any} state - The current state of the use case next button.
 * @param {any} action - The action to be processed.
 * @returns {any} - The updated state with the use case button state.
 */

const usecaseNextButtonReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_USECASE_BUTTON_STATE': return action?.payload;
            break;
        default: return state;
    }
}

export default usecaseNextButtonReducer;