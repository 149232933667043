import { Box, Grid } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const NoAccess = () => {

    const navigate = useNavigate()
    const {t} = useTranslation()
    const location = useLocation();

    // Parse query parameters
    const queryParams = new URLSearchParams(location.search);
    const reason = queryParams.get('from');




    const no_access = process.env.PUBLIC_URL + '/image/icons/no_access.svg';
    const no_access_lock = process.env.PUBLIC_URL + '/image/icons/no_access_lock.svg';


    const navigateToHome = () => {
        navigate("/app/inspection/station")
    }

    const handleLogoutUser = () => {
        navigate('/app/logout/');
    }

    return (
        <Grid container justifyContent={"center"} alignItems={"center"} className='livis-no-access-container'>
            <Grid container item lg={8} md={12} sm={12} xs={12}>
                <Grid item lg={6} md={6} sm={12} xs={12} justifyContent={"center"} alignItems={"center"} display={"flex"} flexDirection={"column"}>
                    <div>
                        <img src={no_access_lock} alt="" />
                    </div>
                    <div className='livis-no-access-heading'>
                        {t("Access Denied")} !
                    </div>
                    <div className='livis-no-access-title'>
                        {/* We’re sorry, but your role currently do not have the required permissions to access this <strong> {reason} </strong>section. Please contact the administrator to gain access. */}
                        {t("We’re sorry, but your role currently do not have the required permissions to access this section. Please contact the administrator to gain access.")}
                    </div>

                    <div className='livis-no-access-route'>
                        {t("Click to go back to")} <span className='livis-no-access-route-home' onClick={navigateToHome}>{t("Homepage")}</span> or <span className='livis-no-access-route-home' onClick={handleLogoutUser}>{t("Logout")}</span>
                    </div>

                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} justifyContent={"center"} alignItems={"center"} display={"flex"} >
                    <img src={no_access} alt="" />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NoAccess