const initialState = {};

/**
 * operatorPanelPreviewDetails: Reducer to handle actions related to the preview modal details in the operator panel.
 * 
 * @param {any} state - The current state of the preview modal details.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const operatorPanelPreviewDetails = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_PREVIEW_MODAL_DETAILS': return action?.payload;
            break;
        default: return state;
    }
}

export default operatorPanelPreviewDetails;