import { useForm } from "react-hook-form";
import SetPassword from "./SetPassword";
import UserDetails from "./UserDetails";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export const getRoles = () => {
    const {t} = useTranslation()
    const roles = [
        {
            label: t("Admin"),
            value: 1,
        },
        {
            label: t("Supervisor"),
            value: "supervisor",
        },
        {
            label: t("Operator"),
            value: "operator",
        }
    ]
    return roles
}


export const roles = [
    {
        label: t("Admin"),
        value: 1,
    },
    {
        label: t("Supervisor"),
        value: "supervisor",
    },
    {
        label: t("Operator"),
        value: "operator",
    }
]

export const getAddUserSteps = () => {
    const {t} = useTranslation()
    const add_user_steps = [
        {
            label: t('User Details'),
            component: UserDetails,
        },
        {
            label: t("Set Password"),
            component: SetPassword
        }
    ];
    return add_user_steps
}

export const add_user_steps = [
    {
        label: 'User Details',
        component: UserDetails,
    },
    {
        label: "Set Password",
        component: SetPassword
    }
];

export const getEditUserSteps = () => {
    const {t} = useTranslation()
    const edit_user_steps = [
        {
            label: t('User Details'),
            component: UserDetails,
        },
    ]
    return edit_user_steps
}

export const edit_user_steps = [
    {
        label: 'User Details',
        component: UserDetails,
    },
]


export const defaultFormValues = {
    name: "",
    last_name: "",
    role: "",
    plant: "",
    email: "",
    phone: "",
    department: "",
    designation: "",
    password: "",
    confirm_password: "",
    credits : 0
}

export const userForm = () => {
    return useForm({
        mode: "all",
        defaultValues: defaultFormValues
    });
};