import { toast } from 'react-toastify';
import { formatString } from './utils';

let lastShownMsg = '';
let lastShownType = '';
let timeoutId: any = null;

export const livisAlert = (message = "Invalid Request", msg_type = "info") => {
    const msg = formatString(message)
    if (timeoutId) {
        return; // If timeout is active, do nothing
    }
    if (msg === lastShownMsg && msg_type === lastShownType) {
        clearTimeout(timeoutId); // Clear previous timeout
        timeoutId = setTimeout(() => {
            lastShownMsg = '';
            lastShownType = '';
            timeoutId = 0
        }, 2000); // Reset after 5 seconds
        return; // If the same message with the same type was shown last time, do nothing
    }

    lastShownMsg = msg;
    lastShownType = msg_type;


    switch (msg_type) {
        case 'success': {
            toast(msg, {
                type: msg_type,
                icon: true,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            break;
        }
        case 'error': {
            toast(msg, {
                type: msg_type,
                icon: true,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            break;
        }
        case 'warning': {
            toast(msg, {
                type: msg_type,
                icon: true,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            break;
        }
        default: {
            toast(msg, {
                type: 'info',
                icon: true,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            break;
        }
    }

    // toast.success("Lorem ipsum dolor")
    // toast.warning("Lorem ipsum dolor")
    // toast.error("Lorem ipsum dolor")

};