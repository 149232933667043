const initialState = false;

/**
 * workstationNextButtonReducer: Reducer to manage the state of the workstation next button.
 * @param {any} state - The current state of the workstation next button.
 * @param {any} action - The action to be processed.
 * @returns {any} - The updated state with the workstation next button state.
 */

const workstationNextButtonReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_WORKSTATION_BUTTON_STATE': return action?.payload;
            break;
        default: return state;
    }
}

export default workstationNextButtonReducer;