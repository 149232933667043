const initialState: any = []

/**
 * reportResponseReducer: Reducer to handle actions related to the report response data.
 * 
 * @param {any} state - The current state of the report response.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const reportResponseReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_REPORT_RESPONSE': return action.payload;
            break;
        default: return state;
    }
}

export default reportResponseReducer;