import Checkbox from '@mui/material/Checkbox';

const LivisCheckBox = (props: any) => {

  const size = props.size ? props.size : 24;
  const disabled = props.disabled ? props.disabled : false
  const checked = props.checked ? props.checked : false
  const livis_on_change_function = props.livis_on_change_function ? props.livis_on_change_function : () => { void 0 }


  

  return (
    <Checkbox
      disabled={disabled}
      checked={checked}
      sx={{
        color: '#B1B1B1',
        '& .MuiSvgIcon-root': { fontSize: size },
        '&.Mui-checked': {
          color: '#4343C7',
        },
        '&:hover': {
          color: '#1A26C1'
        },
      }}
      onChange={livis_on_change_function}
      inputProps={{ 'aria-label': 'controlled' }}
      name={props.name}
    />
  )
}

export default LivisCheckBox