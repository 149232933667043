import { defaultFormValues } from "../../modules/inspection-station/components/AddInspectionStationStepperForm/InspectionStationFormJSON";

const initialState = {
    workstationFormData: defaultFormValues
}

/**
 * workstationReducer: Reducer to manage the state of the workstation form data.
 * @param {any} state - The current state of the workstation form data.
 * @param {any} action - The action to be processed.
 * @returns {any} - The updated state with the workstation form data.
 */

const workstationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_FORM_DATA': return { workstationFormData: action?.payload };
            break;
        default: return state;
    }
}

export default workstationReducer;