const initialStep = true;

/**
 * addUserNextButton: Reducer to handle actions related to the user management button state.
 * 
 * @param {any} state - The current state of the user management button.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const addUserNextButton = (state = initialStep, action: any) => {
    switch (action.type) {
        case 'SET_USER_MANAGEMENT_BUTTON': return action?.payload;
            break;
        default: return state;
    }
}

export default addUserNextButton;