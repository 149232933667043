const initialState: Array<object> = [];

/**
 * tagDataReducer: Reducer to manage tag data state.
 * @param {any} state - The current state of tag data.
 * @param {any} action - The action to be processed.
 * @returns {any} - The updated state based on the action type.
 */

const tagDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_TAG_DATA': return action?.payload;
            break;
        default: return state;
    }
}

export default tagDataReducer;