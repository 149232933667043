const initialState = {
    importFormData: {}
}

/**
 * manageImportDataFormReducer: Reducer to handle actions related to import form data.
 * 
 * @param {any} state - The current state of the import form data.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const manageImportDataFormReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_IMPORT_FORM_DATA': return { importFormData: action?.payload };
            break;
        default: return state;
    }
}

export default manageImportDataFormReducer;