const initialState = false;

/**
 * callReportsReducer: Reducer to handle actions related to calling the reports API.
 * 
 * @param {any} state - The current state related to the reports API call.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const callReportsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_CALL_REPORTS_API_STATE': return action?.payload;
            break;
        default: return state;
    }
}

export default callReportsReducer;