const initialState: Array<object> = [];

/**
 * reportImageVerification: Reducer to handle actions related to report image verification state.
 * 
 * @param {any} state - The current state of the report image verification.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const reportImageVerification = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_REPORT_IMAGE_VERIFICATION': return action?.payload;
            break;
        default: return state;
    }
}

export default reportImageVerification;