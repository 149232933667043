const initialState = false;


/**
 * addDataModalReducer: Reducer to handle actions related to the add data modal state.
 * 
 * @param {any} state - The current state of the add data modal.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const addDataModalReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_ADD_DATA_MODAL_STATE': return action?.payload;
            break;
        default: return state;
    }
}

export default addDataModalReducer;