
const initialState = {
    partFormData: {}
}

/**
 * partFormReducer: Reducer to handle actions related to the part form data.
 * 
 * @param {any} state - The current state of the part form data.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const partFormReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_PART_FORM_DATA': return { partFormData: action?.payload };
            break;
        default: return state;
    }
}

export default partFormReducer;