const initialStep = 0;

/**
 * chooseTagStepperReducer: Reducer to handle actions related to changing the choose tag step.
 * 
 * @param {any} state - The current state of the choose tag step.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const chooseTagStepperReducer = (state = initialStep, action: any) => {
    switch (action.type) {
        case 'CHANGE_CHOOSE_TAG_STEP': return action.payload;
            break;
        default: return state;
    }
}

export default chooseTagStepperReducer;