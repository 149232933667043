export const initialReportPayloadState: any = {
    from_date: '',
    to_date: '',
    limit_to: 20,
    current: 1,
    defect: '',
    feature: '',
    shift: '',
    // verification: false,
    station: '',
    part_number: '',
    operator_name: '',
    batch_no: '',
    day_wise_filter: ''
}

/**
 * reportPayloadReducer: Reducer to handle actions related to the report payload data.
 * 
 * @param {any} state - The current state of the report payload.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const reportPayloadReducer = (state = initialReportPayloadState, action: any) => {
    switch (action.type) {
        case 'SET_REPORT_PAYLOAD': return action.payload;
            break;
        default: return state;
    }
}

export default reportPayloadReducer;