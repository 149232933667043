import ReactDOM from "react-dom/client";
import { createStore } from "redux";
import rootReducer from "./store/reducers/rootReducer";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from 'react-router-dom';
import "./assets/css/livis_theme.scss"
import "./assets/css/livis_operator_panel.scss"
import "./assets/css/livis_operator_report.scss"
import "./assets/css/livis_operator_builder.scss"
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import './localisation/config';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { getLivistheme } from "./helpers/storage"
import { Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./localisation/config";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

//debug mode //console.log or can use babel plugin
if (process.env.NODE_ENV !== "development")
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  //console.log = () => { };

  //livis theme selection js

  (function () {
    if (getLivistheme() === 'livis-dark-theme') {
      document.body.classList.add('livis-dark-theme')
      document.body.classList.remove('livis-light-theme')
    } else {
      document.body.classList.add('livis-light-theme')
      document.body.classList.remove('livis-dark-theme')

    }
  })();

const store = createStore(
  rootReducer
);


// Snippet for Debbugging
// const store = createStore(
//   rootReducer,
//   process.env.NODE_ENV === "development"
//     ? (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
//         (window as any).__REDUX_DEVTOOLS_EXTENSION__()
//     : null
// );


root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </I18nextProvider>
);

//It is called reportWebVitals and it measures a set of metrics that aim to capture the user experience of a web page. This set is called web vitals and includes: First Contentful Paint. Largest Contentful Paint.
reportWebVitals();
