
const initialState = false;


/**
 * addPartWithoutEdge: Reducer to handle actions related to adding a part without an edge.
 * 
 * @param {any} state - The current state of the add part without edge.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const addPartWithoutEdge = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_ADD_PART_WITHOUT_EDGE': return action?.payload;
            break;
        default: return state;
    }
}

export default addPartWithoutEdge;