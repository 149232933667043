import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../../../../components/Form/Input/InputField';
import LivisAutocomplete from '../../../../components/Form/Input/LivisAutocomplete';
import { roles } from './ManageUserFormJSON';
import { setUserManagementButton, setUserManagementData } from '../../../../store/actions';
import { checkUniqueEmail, listAllRoles } from '../../../../services/userManagement';
import { getLivisUserInfo } from '../../../../helpers/storage';
import { livisAlert } from '../../../../helpers/alert';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const UserDetails = (props: any) => {
  const { form, formType } = props;
  const { register, setValue, getValues, control, watch, reset, formState: { errors } } = form;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const firstInputRef = useRef<any>(null);

  const form_data = useSelector((state: any) => state.addUserReducer);
  // const disableNextButton = useSelector((state: any) => state.addUserNextButton);

  const [disableEmail, setDisableEmail] = useState<any>(false)
  const [roleList, setRoleList] = useState<any>([])
  const [isSuperUser, setIsSuperUser] = useState<any>(false)
  const [isUnique, setIsUnique] = useState<any>(false)
  const [debouncerCalled, setDebouncerCalled] = useState<any>(false)
  const [unique, setUnique] = useState("");

  useEffect(() => {
    if (formType == "edit_user") {
      const payload = {
        ...form_data,
        ["credit_arg"]: "+",
        ["credit_value"]: 0,
      }
      reset(payload)
      dispatch(setUserManagementData(payload))
    }
  }, [])



  useEffect(() => {
    console.log(form_data)
    const user_info_string: any = getLivisUserInfo()
    const user_info = JSON.parse(user_info_string)
    setIsSuperUser(user_info?.is_superuser)
    // setIsSuperUser(true)
  }, [form_data])



  const validation_rule = {
    "name": {
      required: {
        value: true,
        message: t("Please enter First Name"),
      },
      minLength: {
        value: 2,
        message: t("First Name must be at least 2 characters"),
      },
      maxLength: {
        value: 50,
        message: t("First Name must be less than 50 characters"),
      }
    },
    "last_name": {
      required: {
        value: true,
        message: t("Please enter Last Name"),
      },
      minLength: {
        value: 2,
        message: t("Last Name must be at least 2 characters"),
      },
      maxLength: {
        value: 50,
        message: t("Last Name must be less than 50 characters"),
      }
    },
    "email": {
      required: {
        value: true,
        message: t("Please enter Email"),
      },
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: t("Please enter valid Email"),
      },
      minLength: {
        value: 2,
        message: t("Email must be at least 2 characters"),
      },
      maxLength: {
        value: 50,
        message: t("Email must be less than 50 characters"),
      }
    },
    "phone": {
      required: {
        value: true,
        message: t("Please enter Phone Number"),
      },
      minLength: {
        value: 5,
        message: t("Phone Number must be at least 5 characters"),
      },
      maxLength: {
        value: 20,
        message: t("Phone Number must be less than 20 characters"),
      }
    },
    "role": {
      // required: {
      //   value: true,
      //   message: t("Please select a role"),
      // },
    },
    "plant": {
      required: {
        value: false,
        message: t("Please enter Plant"),
      },
      minLength: {
        value: 2,
        message: t("Plant must be at least 2 characters"),
      },
      maxLength: {
        value: 50,
        message: t("Plant must be less than 50 characters"),
      }
    },
    "department": {
      required: {
        value: false,
        message: t("Please enter department"),
      },
      minLength: {
        value: 2,
        message: t("Department must be at least 2 characters"),
      },
      maxLength: {
        value: 50,
        message: t("Department must be less than 50 characters"),
      }
    },
    "designation": {
      required: {
        value: false,
        message: t("Please enter Designation"),
      },
      minLength: {
        value: 2,
        message: t("Designation must be at least 2 characters"),
      },
      maxLength: {
        value: 50,
        message: t("Designation must be less than 50 characters"),
      }
    },
    "credits": {}
  }



  useEffect(() => {
    if (firstInputRef.current)
      firstInputRef.current.focus();


    if (props.formType === "edit_user") {
      setDisableEmail(true)
    } else {
      setDisableEmail(false)
    }
    getUserData('all')
  }, []);

  useEffect(() => {
    // console.log(getValues(), getValues().name && getValues().last_name && getValues().email && getValues().phone)
    if ((getValues().name && getValues().last_name && getValues().email && getValues().phone && getValues().group) && isUnique || Object.keys(errors).length === 0) {
      dispatch(setUserManagementButton(false))
    } else {
      dispatch(setUserManagementButton(true))
    }

    if (getValues()?.group) {
      dispatch(setUserManagementButton(false))
    } else {
      dispatch(setUserManagementButton(true))
    }
  }, [watch, getValues(), form])


  const getUserData = async (value: string) => {
    let payload = {};
    payload = {
      is_active: value === "all" ? "" : value === "active" ? 1 : 0
    };
    const response = await listAllRoles(payload);
    const data = response?.data?.data
    const output = data
      .filter((item: any) => item?.is_active) // Filter only active items
      .map((item: any) => ({ value: item?.group_id, label: item?.group_name }));
    console.log(output)
    setRoleList(output)
  }


  const handleInputChange = (e: any) => {
    const { name, value, type, checked } = e.target
    const payload = {
      ...form_data,
      [name]: value
    }
    if (name == "credits" && value >= 10000) {
      payload[name] = 10000
      livisAlert("Maximum assignable credits is 10000", "warning")
    }
    if (name == "credits" && value < 0) {
      payload[name] = 0
    }
    if (name === "group_id") {
      payload["group"] = roleList.find((item: any) => item.value == value)?.label
    }

    // console.log(payload)
    reset(payload)
    dispatch(setUserManagementData(payload))

  }

  // Debounce Method to check unique email 
  const checkUnique = async (payload: any) => {
    const response = await checkUniqueEmail(payload);
    if (response?.status !== 200) {
      setUnique(response?.data?.message);
    } else {
      setUnique("");
      setDebouncerCalled(true)
    }
    return response;
  }

  const handleBlurFunction = async (e: any) => {
    const { name, value } = e.target;

    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const payload = {
      email: value
    }

    setDebouncerCalled(false)
    if (emailRegex.test(value)) {
      console.log(`Valid email entered: ${value}`);
      await checkUnique(payload);
    } else {
      console.log("Invalid email format");
    }
  };

  const handleChangeEditCreditArg = (e: any) => {
    const { name, value } = e.target
    const payload = {
      ...form_data,
      [name]: value,
      ["credit_value"]: 0,
    }
    reset(payload)
    dispatch(setUserManagementData(payload))
  }

  const handleEditCreditChange = (e: any) => {
    const { name, value } = e.target

    if (parseInt(value) > parseInt(form_data?.credits) && form_data?.credit_arg === "-") {
      livisAlert("Invalid Action on credits", "warning")
      return
    }

    if (parseInt(value) + parseInt(form_data?.credits) > 10000 && form_data?.credit_arg === "+") {
      livisAlert("Maximum assignable credits is 10000", "warning")
      return
    }


    const payload = {
      ...form_data,
      [name]: value
    }
    reset(payload)
    dispatch(setUserManagementData(payload))
  }



  return (
    <div className='livis-add-station-wrap'>
      <h4 className="livis-stepper-title">{t("Basic Details")}</h4>
      <Box sx={{ m: 2 }}>
        <Grid className='livis-inspection-station-add-form' container spacing={3} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              name="name"
              control={control}
              rules={validation_rule['name']}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <>
                  <InputField
                    required={true}
                    id="name"
                    name="name"
                    label={t("First Name")}
                    type="text"
                    register={register}
                    rules={validation_rule['name']}
                    errors={errors}
                    livis_on_change={(e: any) => { onChange(e); handleInputChange(e) }}
                    value={value}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              name="last_name"
              control={control}
              rules={validation_rule['last_name']}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <>
                  <InputField
                    required={true}
                    id="last_name"
                    name="last_name"
                    label={t("Last Name")}
                    type="text"
                    register={register}
                    rules={validation_rule['last_name']}
                    errors={errors}
                    livis_on_change={(e: any) => { onChange(e); handleInputChange(e) }}
                    value={value}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <LivisAutocomplete
              name={`role`}
              label={t("Role")}
              control={control}
              rules={validation_rule['role']}
              error={errors?.role?.message}
              defaultValue={getValues()?.group}
              options={roleList}
              livis_on_change={(e: any) => handleInputChange({ target: { name: "group_id", value: e?.value ? parseInt(e.value) : 0 } })}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              name="plant"
              control={control}
              rules={validation_rule['plant']}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <>
                  <InputField
                    required={true}
                    id="plant"
                    name="plant"
                    label={t("Plant (optional)")}
                    type="text"
                    register={register}
                    rules={validation_rule['plant']}
                    errors={errors}
                    livis_on_change={(e: any) => { onChange(e); handleInputChange(e) }}
                  />
                </>
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <div style={{ marginTop: '3rem' }}></div>
      <h4 className="livis-stepper-title">{t("Contact Details")}</h4>
      <Box sx={{ m: 2 }}>
        <Grid className='livis-inspection-station-add-form' container spacing={3} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              name="email"
              control={control}
              rules={validation_rule['email']}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <>
                  <InputField
                    required={true}
                    id="email"
                    name="email"
                    label={t("Email")}
                    type="text"
                    register={register}
                    rules={validation_rule['email']}
                    errors={errors}
                    livis_on_change={(e: any) => { onChange(e); handleInputChange(e) }}
                    livis_on_blur={(e: any) => { onBlur(); handleBlurFunction(e) }}
                    disabled={disableEmail}
                  />
                  <small className="livis-text-danger">
                    {unique && t(unique)}
                  </small>
                </>
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              name="phone"
              control={control}
              rules={validation_rule['phone']}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <>
                  <InputField
                    required={true}
                    id="phone"
                    name="phone"
                    label={t("Phone Number")}
                    type="text"
                    register={register}
                    rules={validation_rule['phone']}
                    errors={errors}
                    livis_on_change={(e: any) => { onChange(e); handleInputChange(e) }}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              name="department"
              control={control}
              rules={validation_rule['department']}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <>
                  <InputField
                    required={true}
                    id="department"
                    name="department"
                    label={t("Department (optional)")}
                    type="text"
                    register={register}
                    rules={validation_rule['department']}
                    errors={errors}
                    livis_on_change={(e: any) => { onChange(e); handleInputChange(e) }}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              name="designation"
              control={control}
              rules={validation_rule['designation']}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <>
                  <InputField
                    required={true}
                    id="designation"
                    name="designation"
                    label={t("Designation (optional)")}
                    type="text"
                    register={register}
                    rules={validation_rule['designation']}
                    errors={errors}
                    livis_on_change={(e: any) => { onChange(e); handleInputChange(e) }}
                  />
                </>
              )}
            />
          </Grid>
        </Grid>
      </Box>
      {
        isSuperUser ?
          <>
            <div style={{ marginTop: '3rem' }}></div>
            <h4 className="livis-stepper-title">{t("Credit Details")}</h4>
            {
              formType === "edit_user" ? (
                <Box sx={{ m: 2, mb: 3 }}>
                  {t("Current Available Credits")}: &nbsp;
                  {
                    // Handle null or undefined values
                    !isNaN(parseInt(form_data?.credits)) && form_data?.credits !== null ?
                      parseInt(form_data?.credits) : 0
                  }
                  &nbsp;
                  {form_data?.credit_arg || ""}
                  &nbsp;
                  {
                    // Handle null or undefined values
                    !isNaN(parseInt(form_data?.credit_value)) && form_data?.credit_value !== null ?
                      parseInt(form_data?.credit_value) : 0
                  }
                  &nbsp;
                  =
                  &nbsp;
                  <strong>
                    {
                      form_data?.credit_arg === "+" ? (
                        <>
                          {
                            // Calculate only if both values are valid numbers
                            !isNaN(parseInt(form_data?.credit_value)) && !isNaN(parseInt(form_data?.credits)) ?
                              parseInt(form_data?.credit_value) + parseInt(form_data?.credits) : 0
                          }
                        </>
                      ) : (
                        <>
                          {
                            // Calculate only if both values are valid numbers
                            !isNaN(parseInt(form_data?.credit_value)) && !isNaN(parseInt(form_data?.credits)) ?
                              parseInt(form_data?.credits) - parseInt(form_data?.credit_value) : parseInt(form_data?.credits)
                          }
                        </>
                      )
                    }
                  </strong>
                </Box>
              ) : null
            }
            <Box sx={{ m: 2 }}>
              <Grid className='livis-inspection-station-add-form' container spacing={3} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {
                  formType == "edit_user" ?
                    <Grid item lg={6} md={6} sm={12} xs={12}>

                      <FormControl variant="standard" className='livis-form-dropdown-select'>
                        <InputLabel id="recipe-station-selector">{t("Credit Action")}</InputLabel>
                        <Select
                          labelId="recipe-station-selector"
                          id="recipe-station-selector"
                          value={getValues()?.credit_arg}
                          onChange={handleChangeEditCreditArg}
                          IconComponent={(props) => (<ExpandMoreIcon {...props} />)}
                          name='credit_arg'
                        >
                          <MenuItem className="livis-menu-item" value="+">{t("Add")}</MenuItem>
                          <MenuItem className="livis-menu-item" value="-">{t("Remove")}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid> : null
                }
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Controller
                    name="credits"
                    control={control}
                    rules={validation_rule['credits']}
                    render={({ field: { onChange, onBlur, value, name } }) => (
                      <>
                        {
                          formType == "edit_user" ?
                            <TextField
                              variant="standard"
                              margin="normal"
                              fullWidth
                              name="credit_value"
                              label={t("Credits")}
                              type="number"
                              className="livis-input-field"
                              InputLabelProps={{
                                style: { fontFamily: "Noto Sans Regular" },
                              }}
                              value={form_data?.credit_value}
                              onChange={(e: any) =>
                                handleEditCreditChange(e)
                              }
                            />
                            :
                            <TextField
                              variant="standard"
                              margin="normal"
                              fullWidth
                              name="credits"
                              label={t("Credits")}
                              type="number"
                              className="livis-input-field"
                              InputLabelProps={{
                                style: { fontFamily: "Noto Sans Regular" },
                              }}
                              value={form_data?.credits}
                              onChange={(e: any) =>
                                handleInputChange(e)
                              }
                            />
                        }
                      </>
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
          : null
      }
    </div >
  )
}

export default UserDetails