
const initialState = false;

/**
 * importIncludeAnnotation: Reducer to handle actions related to including annotation in the import process.
 * 
 * @param {any} state - The current state related to import annotation inclusion.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const importIncludeAnnotation = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_IMPORT_INCLUDE_ANNOTATION': return action?.payload;
            break;
        default: return state;
    }
}

export default importIncludeAnnotation;