const initialState: Array<object> = [];

/**
 * recipeListReducer: Reducer to handle actions related to the recipe list data.
 * 
 * @param {any} state - The current state of the recipe list data.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const recipeListReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_RECIPE_LIST_DATA': return action?.payload;
            break;
        default: return state;
    }
}

export default recipeListReducer;