const initialState = {}

/**
 * operatorBuilderConfigPayload: Reducer to handle actions related to editing the operator builder configuration payload.
 * 
 * @param {any} state - The current state of the operator builder configuration payload.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const operatorBuilderConfigPayload = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_EDIT_OPERATOR_BUILDER_CONFIG_PAYLOAD': return action?.payload ;
            break;
        default: return state;
    }
}

export default operatorBuilderConfigPayload;