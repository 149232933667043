import { lazyImport } from '../helpers/lazyImport';
import { NotFound } from '../modules/misc';

const { AuthRoutes }: any = lazyImport(() => import('../modules/auth'), 'AuthRoutes');
const { TestRoutes }: any = lazyImport(() => import('../modules/test'), 'TestRoutes');
const { DashboardRoutes }: any = lazyImport(() => import('../modules/dashboard'), 'DashboardRoutes');

export const publicRoutes = [
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
  {
    path: '/test/*',
    element: <TestRoutes />,
  },
  {
    path: '/app/dashboard/*',
    element: <DashboardRoutes />,
  },
  {
    path: '*',
    element: <NotFound />,
  }
]
