const initialStep = false;

/**
 * skipPeripheralStepperReducer: Reducer to manage the state for skipping the peripheral step.
 * @param {any} state - The current state of the peripheral step.
 * @param {any} action - The action to be processed.
 * @returns {any} - The updated state based on the action type.
 */

const skipPeripheralStepperReducer = (state = initialStep, action: any) => {
    switch (action.type) {
        case 'SKIP_PERIPHERAL_STEP': return action.payload;
            break;
        default: return state;
    }
}

export default skipPeripheralStepperReducer;