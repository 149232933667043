const initialState = {
    isLoading: false,
}

/**
 * SpinnerReducerBlock: Reducer to manage the loading state with a block overlay.
 * @param {any} state - The current state of the loading block.
 * @param {any} action - The action to be processed.
 * @returns {any} - The updated state based on the action type.
 */

const SpinnerReducerBlock = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_LOADING_STATE_BLOCK': return { isLoading: action?.payload };
            break;
        default: return state;
    }
}

export default SpinnerReducerBlock;