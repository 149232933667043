export const operatorPanelInitialState = {
    rotate: { min: -90, max: 45 },
    flip_vr: { p: 1.0 },
    flip_hr: { p: 1.0 },
    contrast: { gamma: 1.3 },
    brightness: { min: -50, max: 65 },
    drop: { p: 0.6 },
    noise: { p: 0.4 },
    gray_scale: { alpha: 0.25 }
};


/**
 * agumentationDataReducer: Reducer to handle actions related to augmentation data.
 * 
 * @param {any} state - The current state of the augmentation data.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const agumentationDataReducer = (state = operatorPanelInitialState, action: any) => {
    switch (action.type) {
        case 'SET_AUGMENTATION_DATA':
            return {
                ...action.payload,
            };
        default:
            return state;
    }
};

export default agumentationDataReducer;
