import Axios from "axios";
import { t } from "i18next";
import { livisAlert } from "./alert";
import { LIVIS_API_BASE_URL } from "../config";
import {
  getAlertStatus,
  getLivisToken,
  getLivisUserInfo,
  getRefreshToken,
  removeAlertStatus,
  resetLivisUserInfo,
  setLivisUserInfo,
} from "./storage";
import { useNavigate } from "react-router-dom";

const refreshLivisToken = async () => {
  const refresh_token = getRefreshToken();
  if (!refresh_token) {
    resetLivisUserInfo();   // get the refresh token, if not then logout
    return false;
  } else {
    try {
      // if refresh token present call the API to get new access_token
      const response = await fetch(`${LIVIS_API_BASE_URL}authorization/refresh_token/`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({ "refresh_token": refresh_token })
      });

      if (response.status === 401) {
        // if the response status is 401, logout the user
        resetLivisUserInfo();
        window.location.href = "/"
        return false;
      }

      const data = await response.json();
      console.log(data, "===========>>>>");

      const user_info = data;
      const currentUserInfo: any = getLivisUserInfo();
      const parsedCurrentUserInfo = JSON.parse(currentUserInfo);
      const new_user_info = {
        ...parsedCurrentUserInfo,
        access_token : user_info?.data?.access_token,
        refresh_token : user_info?.data?.refresh_token,
      };
      console.log(user_info, parsedCurrentUserInfo, new_user_info, "===========================>");

      if (new_user_info) {
        // if refresh token is valid then store in local storage
        setLivisUserInfo(JSON.stringify(new_user_info));
        return true;
      } else {
        // if access token is unauthorized then logout
        resetLivisUserInfo();
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  return false;
}

function authRequestInterceptor(config: any) {
  const token = getLivisToken();
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  config.headers.Accept = "application/json";
  // //console.log(config.headers)
  config.headers["Content-Type"] = "application/json";

  return config;
}

export const axios = Axios.create({
  baseURL: window.location.pathname === '/app/operator/report' || window.location.pathname === '/app/operator/home' ? 'http://localhost:9000/' : LIVIS_API_BASE_URL,
});

const restricted_url = ["parts/check_part_uniqueness/", "authorization/login/", "workstations/check_unique_workstation_name/", "usecase/check_usecase_uniqueness/", "parameters/get_all_parameters/"]

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    // const no_alert_status = getAlertStatus();
    // const restrict_url = ((response.config.url === "authorization/login/" || response.config.url === "workstations/check_unique_workstation_name/" || response.config.url === 'usecase/check_usecase_uniqueness/' || response.config.url === "parameters/get_all_parameters/" || response.config.url === "parts/check_part_uniqueness/") ? true : false);
    // if (!no_alert_status && !restrict_url) {
    //   const msg = t(`${response?.data?.message}`)
    //   livisAlert(msg, "success");
    // }
    // removeAlertStatus();
    return response;
  },
  async (error) => {
    if (error?.message === 'Network Error') {
      livisAlert(error?.message, "error");
      return null;
    }
    const err_msg = t(`${error.response.data.message}`)
    const no_alert_status = getAlertStatus();
    const restrict_url = ((error?.config?.url === "authorization/login/" || error?.config?.url === "workstations/check_unique_workstation_name/" || error.config.url === 'usecase/check_usecase_uniqueness/' || error.config.url === "parameters/get_all_parameters/" || error.config.url === "parts/check_part_uniqueness/") ? true : false);
    const status = error?.response?.status;
    let error_status = false
    if (status === 401) {
      const access_token = await refreshLivisToken();
      if (access_token) {
        switch (error.config.method) {
          case 'get': {
            error_status = await axios.get(error.config.url);
          }
            break;

          case 'post': {
            error_status = await axios.post(error.config.url, error.config.data);
          }
            break;

          case 'patch': {
            error_status = await axios.post(error.config.url, error.config.data);
          }
            break;

          case 'delete': {
            error_status = await axios.delete(error.config.url);
          }
            break;
        }
      } else {
        return error.response;
      }
      removeAlertStatus();
      return error_status;
    }
    if (status === 403) {
      livisAlert("User does not have permission to perform this action", "warning")
    }
    if (!no_alert_status && !restrict_url) {

      switch (status) {
        case 400: {
          livisAlert(err_msg, "error");
          break;
        }
        case 403: {
          livisAlert(err_msg, "error");
          break;
        }
        case 404: {
          livisAlert(err_msg, "error");
          error_status = error.response;
          break;
        }
        case 500: {
          livisAlert("Internal server error", "error");
          break;
        }
        default: {
          livisAlert("Unknown Error", "error");
          break;
        }
      }
      removeAlertStatus();
      return error_status;
    } else {
      // switch (status) {
      //   case 400: {
      //     window.location.href = "/"
      //     break;
      //   }
      // }
      // switch(status){
      //   case 400: {
      //     //console.log("hi")
      //     livisAlert(error.response.data.message, "error");
      //     break;
      //   }
      // }
      return error.response;
    }
  }
);
